import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { CgSearch } from 'react-icons/cg';

import './styles.scss';

const SearchInput = ({
  placeholder = 'start your search...',
  value,
  onSetValue,
  onSubmit,
}) => {
  const handleSubmit = event => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="pvda-search-input">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={({ target }) => onSetValue(target.value)}
        />
        <CgSearch color="#C4C3D1" size={18} onClick={() => onSubmit(value)} />
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSetValue: PropTypes.func,
  onSubmit: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
  onSetValue() {},
  onSubmit() {},
};

export default memo(SearchInput);
