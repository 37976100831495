/**
 *
 * ActivityLogLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ActivityLogLoading = ({ counter }) =>
  Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-kpi-activity-loading">
      <Placeholder animation="glow">
        <Placeholder xs={4} size="xs" />
        <Placeholder xs={12} />
        <Placeholder xs={8} size="xs" />
      </Placeholder>
    </div>
  ));

ActivityLogLoading.propTypes = { counter: PropTypes.number };

ActivityLogLoading.defaultProps = { counter: 0 };

export default memo(ActivityLogLoading);
