/**
 *
 * FunctionsKPIsLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ViewChartLoading = ({ counter }) => (
  <div className="pvda-functions-kpi-view-chart-loading">
    {Array.from(Array(counter), (_, i) => (
      <div key={i} className="pvda-functions-kpi-view-chart-loading__card">
        <Placeholder
          animation="glow"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Placeholder
            style={{
              width: '100%',
              height: 229,
              marginTop: 8,
            }}
          />
        </Placeholder>
      </div>
    ))}
  </div>
);

ViewChartLoading.propTypes = { counter: PropTypes.number };

ViewChartLoading.defaultProps = { counter: 3 };

export default memo(ViewChartLoading);
