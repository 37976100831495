import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { FaRegClock, FaStar } from 'react-icons/fa';

import { getLastTimeUpdated } from 'services/format/date';

import './styles.scss';

const RecentlyReportCard = ({
  title,
  visitedAt,
  isFavorites,
  opu,
  categoryType,
}) => (
  <div className="pvda-recent-report-card">
    <div className="pvda-recent-report-card__opu">{opu?.name}</div>
    <div className="pvda-recent-report-card__name">{title}</div>
    <div className="pvda-recent-report-card__category-type">{categoryType}</div>
    <div className="pvda-recent-report-card__updated-date">
      <FaRegClock color="#C4C3D1" size={12} /> {getLastTimeUpdated(visitedAt)}
    </div>
    {isFavorites && (
      <div className="pvda-recent-report-card__favourites">
        <FaStar color="#FCAA1B" size={18} />
      </div>
    )}
  </div>
);

RecentlyReportCard.propTypes = {
  isFavorites: PropTypes.bool,
  title: PropTypes.string,
  visitedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opu: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
};

RecentlyReportCard.defaultProps = {
  isFavorites: false,
  title: '',
  visitedAt: '',
};

export default memo(RecentlyReportCard);
