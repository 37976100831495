/**
 *  CONSTANTS REPORT STATUS
 */

import { PENDING_TYPE, REJECTED_TYPE } from './reportStatusTypes';

export const TO_REVIEW = 0;
export const DRAFT = 1;
export const PENDING_REVIEW = 2;
export const REVIEW_REJECTED = 3;
export const PENDING_APPROVAL = 4;
export const APPROVAL_REJECTED = 5;
export const PUBLISHED = 6;
export const REPORT_AMENDED = 7;
export const PUBLISHING = 8;
export const REVIEWED = 9;
export const PENDING_REVIEW_KPI_CATEGORY = 10;
// export const PENDING_PUBLISH = 9;

export const CURRENT_TASKS = {
  DATA_VALIDATION: 1,
  PENDING_REVIEW_KPI_CATEGORY: 2,
  PENDING_REVIEW: 3,
  PENDING_APPROVAL: 4,
  PUBLISHING: 5,
  REPORT_PUBLISHED: 5,
};

export const ACTION_TYPE = {
  SUBMIT: 0,
  APPROVE: 1,
  REJECT: 2,
};

export const DATA_VALIDATION_TASK = {
  id: '113c4f35-dc81-45e0-99be-367ba9bb4c3b',
  name: 'Data validation',
  currentTask: CURRENT_TASKS.DATA_VALIDATION,
};

export const PENDING_REVIEW_KPI_CATEGORY_TASK = {
  name: 'KPI Category Pending Review',
  currentTask: CURRENT_TASKS.PENDING_REVIEW_KPI_CATEGORY,
};

export const PENDING_REVIEW_TASK = {
  id: 'f122d110-f15a-45ea-b674-7cb4c009e761',
  name: 'Pending review',
  currentTask: CURRENT_TASKS.PENDING_REVIEW,
};

export const PENDING_APPROVAL_TASK = {
  id: 'b6c7365d-736f-41e0-ba44-4802e6149609',
  name: 'Pending approval',
  currentTask: CURRENT_TASKS.PENDING_APPROVAL,
};

export const REPORT_PUBLISHED_TASK = {
  id: 'faf570b8-bd53-4b33-9dd6-22777355247d',
  name: 'Report published',
  currentTask: CURRENT_TASKS.REPORT_PUBLISHED,
};

export const PUBLISHING_TASK = {
  id: 'e347b1f5-a922-47f0-a4cd-96f09c779fe7',
  name: 'Publishing',
  currentTask: CURRENT_TASKS.PUBLISHING,
};

export const PROCESS_BAR_REPORT_STATUS = [
  DATA_VALIDATION_TASK,
  PENDING_REVIEW_KPI_CATEGORY_TASK,
  PENDING_REVIEW_TASK,
  PENDING_APPROVAL_TASK,
  // PUBLISHING_TASK,
  REPORT_PUBLISHED_TASK,
];

export const REPORT_STATUS = {
  [TO_REVIEW]: {},
  [DRAFT]: {
    id: DRAFT,
    name: 'draft',
    text: 'Draft',
    currentTask: CURRENT_TASKS.DATA_VALIDATION,
    bgColor: '#FEDC92',
    color: '#3F3C4C',
  },
  [PENDING_REVIEW]: {
    id: PENDING_REVIEW,
    name: 'pendingReview',
    text: 'Pending review',
    currentTask: CURRENT_TASKS.PENDING_REVIEW,
    bgColor: '#FEDC92',
    color: '#3F3C4C',
    type: PENDING_TYPE,
  },
  [PENDING_REVIEW_KPI_CATEGORY]: {
    id: PENDING_REVIEW_KPI_CATEGORY,
    name: 'pendingReviewKPICategory',
    text: 'Pending Review KPI Category',
    currentTask: CURRENT_TASKS.PENDING_REVIEW_KPI_CATEGORY,
    bgColor: '#FEDC92',
    color: '#3F3C4C',
    type: PENDING_TYPE,
  },
  [REVIEW_REJECTED]: {
    id: REVIEW_REJECTED,
    name: 'reviewRejected',
    text: 'Review rejected',
    currentTask: CURRENT_TASKS.DATA_VALIDATION,
    bgColor: '#FFF2F2',
    color: '#E82121',
    type: REJECTED_TYPE,
  },
  [PENDING_APPROVAL]: {
    id: PENDING_APPROVAL,
    name: 'pendingApproval',
    text: 'Pending approval',
    currentTask: CURRENT_TASKS.PENDING_APPROVAL,
    bgColor: '#FEDC92',
    color: '#3F3C4C',
    type: PENDING_TYPE,
  },
  [APPROVAL_REJECTED]: {
    id: APPROVAL_REJECTED,
    name: 'approvalRejected',
    text: 'Approval rejected',
    currentTask: CURRENT_TASKS.DATA_VALIDATION,
    bgColor: '#FFF2F2',
    color: '#E82121',
    type: REJECTED_TYPE,
  },
  [PUBLISHING]: {
    id: PUBLISHING,
    name: 'publishing',
    text: 'Publishing',
    currentTask: CURRENT_TASKS.PUBLISHING,
    bgColor: '#FEDC92',
    color: '#3F3C4C',
    type: PENDING_TYPE,
  },
  [REVIEWED]: {
    id: REVIEWED,
    name: 'reviewed',
    text: 'Reviewed',
    bgColor: '#D9F2F7',
    color: '#3F3C4C',
  },
  [PUBLISHED]: {
    id: PUBLISHED,
    name: 'published',
    text: 'Published',
    currentTask: CURRENT_TASKS.REPORT_PUBLISHED,
    bgColor: '#D9F2F7',
    color: '#3F3C4C',
  },
};
