import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const MyFeedLoading = ({ counter }) =>
  Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-my-feed-loading">
      <Placeholder as="p" animation="glow">
        <Placeholder xs={4} size="xs" />
      </Placeholder>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={6} />
        <Placeholder xs={12} />
        <Placeholder xs={12} size="xs" />
      </Placeholder>
      <Placeholder.Button
        variant="custom-primary"
        xs={6}
        style={{ marginTop: 16 }}
      />
    </div>
  ));

MyFeedLoading.propTypes = { counter: PropTypes.number };

MyFeedLoading.defaultProps = { counter: 0 };

export default memo(MyFeedLoading);
