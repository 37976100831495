import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addUsersAvatar, usersAvatarSelector } from '../slices/user';

const useUsersAvatar = () => {
  const usersAvatar = useSelector(usersAvatarSelector);

  const dispatch = useDispatch();

  const onAddUsersAvatar = useCallback(data => {
    dispatch(addUsersAvatar(data));
  }, []);

  return {
    usersAvatar,
    onAddUsersAvatar,
  };
};

export default useUsersAvatar;
