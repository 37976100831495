import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import useOPU from 'hooks/useOPU';
import { FaCircle } from 'react-icons/fa';

const SubMenuItem = ({
  menu,
  onSetOPUActivated
}) => {
  const { opuActivated } = useOPU();
  const toNotificationFormat = number => (number > 99 ? '99+' : number);

  const onClick = useCallback(opu => {
    onSetOPUActivated(opu);
  });

  return (
    <div className="sidebar-menu__sub-menu">
      <div className="sub-menu-content">
        <div
          className={`pvda-opu-menu__item-content${
            menu?.id === opuActivated?.id
              ? ' pvda-opu-menu__item-content--activated'
              : ''
          }`}
          role="presentation"
          onClick={() => onClick(menu)}
        >
          <span className="sub-menu-title">{menu?.name}</span>
          <div className="pvda-opu-menu__item-icon">
            {menu?.pendingActionCount ? (
              <div className="pvda-opu-menu__item-notifi">
                {toNotificationFormat(menu?.pendingActionCount)}
              </div>
            ) : (
              <FaCircle color="#8879C9" size={8} />
            )}
          </div>
        </div>
        <div className="sub-menu__tooltip">{menu?.name}</div>
      </div>
    </div>
  );
};

SubMenuItem.propTypes = {
  menu: PropTypes.object,
  pendingActionCount: PropTypes.number,
  onSetOPUActivated: PropTypes.func,
};
SubMenuItem.defaultProps = {
  menu: {},
  pendingActionCount: 0,
  onSetOPUActivated() {},
};

export default memo(SubMenuItem);
