import React, { memo } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const ScrollMore = React.forwardRef((props, ref) => {
  const handleScroll = e => {
    if (props.isTopScrollMore) {
      e.target.scrollTop === 0 && props.onScrollMore();
    } else {
      // sub more 1 for screen small, the value of e.target.scrollTop has been rounded down
      const bottom =
        e.target.scrollHeight - Math.floor(e.target.scrollTop) - 1 <=
        e.target.clientHeight;

      if (bottom) {
        props.onScrollMore();
      }
    }
  };

  return (
    <div ref={ref} className={props.className} onScroll={handleScroll}>
      {props.children}
    </div>
  );
});

ScrollMore.propTypes = {
  children: PropTypes.any.isRequired,
  isTopScrollMore: PropTypes.bool,
  className: PropTypes.string,
  onScrollMore: PropTypes.func.isRequired,
};

ScrollMore.defaultProps = {
  isTopScrollMore: false,
  className: '',
};

export default memo(ScrollMore);
