import React, { memo } from 'react';

import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ViewPerformanceAnalysisLoading = () => (
  <div className="pvda-view-performance-analysis-loading">
    <div className="pvda-view-performance-analysis-loading__item">
      <Placeholder xs={6} />
      <Placeholder.Button xs={1} aria-hidden="true" />
    </div>
  </div>
);

ViewPerformanceAnalysisLoading.propTypes = {};

export default memo(ViewPerformanceAnalysisLoading);
