import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'categoryTypes';
const initialState = {
  categoryTypes: {
    data: [],
    status: 'loading', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  newCategoryType: {
    data: null,
    status: 'idle',
    error: null,
  },
};

const categoryTypesSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getCategoryTypesRequest(state) {
      state.categoryTypes.status = 'loading';
    },
    getCategoryTypesSuccess(state, action) {
      state.categoryTypes.data = action.payload;
      state.categoryTypes.status = 'succeeded';
    },
    getCategoryTypesFailure(state, action) {
      state.categoryTypes.status = 'failed';
      state.categoryTypes.data = {};
      state.categoryTypes.error = action.payload;
    },
    addNewCategoryTypeRequest(state) {
      state.newCategoryType.status = 'loading';
    },
    addNewCategoryTypeSuccess(state) {
      state.newCategoryType.status = 'succeeded';
      state.newCategoryType.error = null;
    },
    addNewCategoryTypeFailure(state, action) {
      state.newCategoryType.status = 'failed';
      state.newCategoryType.error = action.payload;
    },
  },
});

const { actions, reducer } = categoryTypesSlice;

export default reducer;
export const {
  getCategoryTypesRequest,
  getCategoryTypesSuccess,
  getCategoryTypesFailure,
  addNewCategoryTypeRequest,
  addNewCategoryTypeSuccess,
  addNewCategoryTypeFailure,
} = actions;
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const categoryTypesSelector = createSelector(
  rootSelector,
  ({ categoryTypes }) => categoryTypes,
);
export const newCategoryTypeSelector = createSelector(
  rootSelector,
  state => state.newCategoryType,
);
