import React, { memo, useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { FaRegBell, FaRegClock } from 'react-icons/fa';
import { css } from '@emotion/react';

import { GET_NOTIFICATION_PATH } from 'constants/paths';
import { RECENTLY_UPDATED_REPORTS } from 'constants/props';

import useClickOutside from 'hooks/useClickOutside';
import useNewsReport from 'hooks/useNewsReports';

import { updateIsShowRightBar } from 'slices/user';

import UserInfoAvatar from '../UserInfoAvatar';
import MyFeed from '../MyFeed';
import MyRecent from '../MyRecent';

import './styles.scss';

const MY_FEED = 'MY_FEED';
const MY_RECENT = 'MY_RECENT';
const DEFAULT_QUERY = {
  pageIndex: 0,
  pageSize: 4,
};
const RightSidebar = ({ userInfo, opuActivated }) => {
  const [query, setQuery] = useState(null);
  const [isSideBarExpand, setSideBarExpand] = useState(false);
  const [workspaceActivated, setWorkspaceActivated] = useState(null);

  const dispatch = useDispatch();

  const rightSidebarRef = useRef();

  const { reports: myFeedReports, onGetReportsByProp } = useNewsReport(
    RECENTLY_UPDATED_REPORTS,
  );

  const onScrollMore = useCallback(() => {
    if (
      myFeedReports.status === 'succeeded' &&
      myFeedReports.data?.hasNextPage
    ) {
      setQuery(prev => ({
        ...prev,
        pageIndex: prev.pageIndex + 1,
      }));
    }
  }, [myFeedReports]);

  useEffect(() => {
    if (opuActivated?.id) {
      setQuery({
        ...DEFAULT_QUERY,
        getBeforeTime: new Date().toUTCString(),
      });
    }
  }, [opuActivated?.id]);

  useEffect(() => {
    if (query) {
      onGetReportsByProp({
        url: GET_NOTIFICATION_PATH,
        query: {
          ...query,
          opuId: opuActivated?.id,
        },
        isLoadMore: true,
      });
    }
  }, [query]);

  const onSetWorkspaceActivated = newWorkspaceActivated => {
    if (newWorkspaceActivated === workspaceActivated) {
      setWorkspaceActivated(null);
      setSideBarExpand(false);
      dispatch(updateIsShowRightBar(false));
    } else {
      setWorkspaceActivated(newWorkspaceActivated);
      setSideBarExpand(true);
      dispatch(updateIsShowRightBar(true));
    }
  };

  useClickOutside(rightSidebarRef, () =>
    onSetWorkspaceActivated(workspaceActivated),
  );

  return (
    <div
      ref={rightSidebarRef}
      className={`pvda-right-sidebar pvda-right-sidebar--${
        isSideBarExpand ? 'expand' : 'default'
      }`}
    >
      <div className="pvda-right-sidebar__left">
        <div className="pvda-right-sidebar__button">
          <UserInfoAvatar userInfo={userInfo} showLogout />
        </div>
        <div
          role="presentation"
          style={{ position: 'relative' }}
          className="pvda-right-sidebar__button"
          onClick={() => onSetWorkspaceActivated(MY_FEED)}
        >
          <FaRegBell
            size={18}
            color={workspaceActivated === MY_FEED ? '#463E78' : '#BFCDD1'}
          />
          {myFeedReports.data?.unreadAction > 0 && (
            <sup
              css={css`
                position: absolute;
                top: 20px;
                right: 20px;
              `}
            >
              <div
                css={css`
                  width: 0.625rem;
                  height: 0.625rem;
                  border-radius: 50%;
                  border: 2px solid #ffffff;
                  background: #26afab;
                `}
              />
            </sup>
          )}
        </div>
        <div
          role="presentation"
          className="pvda-right-sidebar__button"
          onClick={() => onSetWorkspaceActivated(MY_RECENT)}
        >
          <FaRegClock
            size={18}
            color={workspaceActivated === MY_RECENT ? '#463E78' : '#BFCDD1'}
          />
        </div>
      </div>
      <div className="pvda-right-sidebar__right">
        {workspaceActivated === MY_FEED && (
          <MyFeed
            userInfo={userInfo}
            opuActivated={opuActivated}
            reports={myFeedReports}
            onScrollMore={onScrollMore}
          />
        )}
        {workspaceActivated === MY_RECENT && <MyRecent userInfo={userInfo} />}
      </div>
    </div>
  );
};

RightSidebar.propTypes = {
  userInfo: PropTypes.object,
  opuActivated: PropTypes.object,
};

RightSidebar.defaultProps = {
  userInfo: {},
  opuActivated: {},
};

export default memo(RightSidebar);
