import { all } from 'redux-saga/effects';

import userSaga from './userSaga';
import opuSaga from './opuSaga';
import reportSaga from './reportSaga';
import newsReportSaga from './newsReportSaga';
import categorySaga from './categorySaga';
import categoryTypeSage from './categoryTypeSaga';
import insightSaga from './insightSaga';
import functionSaga from './functionSaga';
import kpiSaga from './kpiSaga';
import kpiDetailSaga from './kpiDetailSaga';
import clarificationSaga from './clarificationSaga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    opuSaga(),
    reportSaga(),
    newsReportSaga(),
    categorySaga(),
    categoryTypeSage(),
    insightSaga(),
    functionSaga(),
    kpiSaga(),
    kpiDetailSaga(),
    clarificationSaga(),
  ]);
}
