export const RELOAD_LIMITED = 4;

export const SOCKET_RESPONSE_TYPES = {
  CREATED_MESSAGE: 'created-message',
  SUBSCRIBE_SUCCESS: 'subscribe-success',
  SUBSCRIBE_FAIL: 'subscribe-fail',
  UNSUBSCRIBE_SUCCESS: 'unsubscribe-success',
  UNSUBSCRIBE_FAIL: 'unsubscribe-fail',
  NOTIFICATION: 'notification',
};

export const NOTIFICATION_TYPE = {
  PENDING: 'Pending',
  NOTIFICATION: 'Notification',
  PROGRESS: 'Progress',
  HISTORY: 'History',
  KPI_REFRESH: 'KpiRefreshed',
};
