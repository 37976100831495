import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getData, patchData, putData, deleteData } from '../services/api';
import { standardizedKPIData } from '../services/standardizedData';

import {
  PERFORMANCE_ANALYSES_PATH,
  ADD_OR_UPDATE_PERFORMANCE_ANALYSIS,
  GET_INFO_OF_KPI,
  GET_PERFORMANCE_ANALYSIS_PATH,
  GET_BREADCRUMB,
  GET_ACTIVITY_LOG,
  GET_CONTRIBUTING_IMPACTED_KPIS,
  GET_DATA_CHART_PATH,
  ADD_CONFIG_CHART_PATH,
  EDIT_CONFIG_CHART_PATH,
  DELETE_DATA_VISUALISATION_CHART_PATH,
  KPI_PATH,
  GET_DASHBOARD_URL_PATH,
  UPDATE_SHOW_CHART_STATUS_PATH,
  DELETE_PERFORMANCE_ANALYSIS_PATH,
  GET_KPIS_SAME_LEVEL,
} from '../constants/paths';

import {
  getKPIRequest,
  getKPISuccess,
  getKPIFailure,
  updateForecastDataRequest,
  updateForecastDataFailure,
  updateForecastDataSuccess,
  addOrUpdatePerfAnalysisRequest,
  addOrUpdatePerfAnalysisSuccess,
  addOrUpdatePerfAnalysisFailure,
  deletePerfAnalysisRequest,
  deletePerfAnalysisSuccess,
  deletePerfAnalysisFailure,
  getInfoOfKpiSuccess,
  getInfoOfKpiFailure,
  getInfoOfKpiRequest,
  getPerfAnalysisSuccess,
  getPerfAnalysisFailure,
  getPerfAnalysisRequest,
  getBreadcrumbsRequest,
  getBreadcrumbsSuccess,
  getBreadcrumbsFailure,
  getActivityLogSuccess,
  getActivityLogFailure,
  getActivityLogRequest,
  getKPIsByPropRequest,
  getKPIsByPropSuccess,
  getKPIsByPropFailure,
  getDataChartSuccess,
  getDataChartFailure,
  getDataChartRequest,
  addConfigChartRequest,
  addConfigChartSuccess,
  addConfigChartFailure,
  editConfigChartRequest,
  editConfigChartSuccess,
  editConfigChartFailure,
  deleteDataVisualisationChartRequest,
  deleteDataVisualisationChartSuccess,
  deleteDataVisualisationChartFailure,
  getDashboardUrlSuccess,
  getDashboardUrlFailure,
  getDashboardUrlRequest,
  updateShowChartStatusRequest,
  updateShowChartStatusSuccess,
  updateShowChartStatusFailure,
  getKPIsSameLevelRequest,
  getKPIsSameLevelSuccess,
  getKPIsSameLevelFailure,
} from '../slices/kpi';

import { updateTimeOfReport } from '../slices/report';

export function* getKPI({ payload }) {
  try {
    const [, { data: perfAnalysis }] = yield all([
      call(putData, { url: `${KPI_PATH}/${payload.kpiId}/CreateHierarchy` }),
      call(getData, {
        url: PERFORMANCE_ANALYSES_PATH,
        params: payload,
      }),
    ]);

    if (perfAnalysis?.IsSuccess) {
      yield put(
        getKPISuccess(
          standardizedKPIData({
            ...perfAnalysis?.Data,
            frequency: payload?.frequencyType,
          }),
        ),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(getKPIFailure(message));
  }
}

export function* updateForecastData({ payload }) {
  try {
    const {
      data: { IsSuccess },
    } = yield call(patchData, {
      url: PERFORMANCE_ANALYSES_PATH,
      data: { forecastValues: payload?.updatedForecastData },
    });

    if (IsSuccess) {
      yield put(updateForecastDataSuccess());
      if (payload?.onUpdateChartData) {
        payload.onUpdateChartData();
      }
      if (payload?.onSaveSuccess) {
        payload.onSaveSuccess();
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(updateForecastDataFailure(message));
    if (payload?.onSaveFailed) {
      payload.onSaveFailed();
    }
  }
}

export function* addOrUpdatePerfAnalysis({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: ADD_OR_UPDATE_PERFORMANCE_ANALYSIS,
      data: payload?.data,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(
        addOrUpdatePerfAnalysisSuccess({
          data: Data || {},
          kpiId: payload?.data?.kpiId,
        }),
      );
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      addOrUpdatePerfAnalysisFailure({
        error: responseMessage?.Message || message,
      }),
    );

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* getInfoOfKPI({ payload }) {
  try {
    const {
      data: { IsSuccess, Data },
    } = yield call(getData, {
      url: GET_INFO_OF_KPI,
      params: payload,
    });
    if (IsSuccess) {
      yield put(getInfoOfKpiSuccess(Data || {}));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getInfoOfKpiFailure(message));
  }
}

export function* getPerfAnalysis({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_PERFORMANCE_ANALYSIS_PATH,
      params: { kpiId: payload.kpiId },
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(
        getPerfAnalysisSuccess({
          kpiId: payload.kpiId,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getPerfAnalysisFailure({
        kpiId: payload.kpiId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* getBreadcrumbs({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_BREADCRUMB,
      params: payload,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(getBreadcrumbsSuccess(Data));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getBreadcrumbsFailure(message));
  }
}

export function* getActivityLog({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_ACTIVITY_LOG,
      params: payload,
    });

    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(
        getActivityLogSuccess({
          query: { ...payload },
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getActivityLogFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* getKPIsByProp({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_CONTRIBUTING_IMPACTED_KPIS,
      params: payload.params,
    });

    const { IsSuccess, Data } = data;

    if (IsSuccess) {
      yield put(
        getKPIsByPropSuccess({
          prop: payload.prop,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(
      getKPIsByPropFailure({
        prop: payload.prop,
        error: message,
      }),
    );
  }
}

export function* getDataChart({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_DATA_CHART_PATH,
      params: payload?.query,
    });

    const { IsSuccess, Data } = data;

    if (IsSuccess) {
      yield put(
        getDataChartSuccess({
          kpiId: payload?.query?.kpiId,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getDataChartFailure({
        kpiId: payload?.query?.kpiId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* addConfigChart({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: ADD_CONFIG_CHART_PATH,
      data: payload?.data,
    });
    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(
        addConfigChartSuccess({
          kpiId: payload?.data?.kpiId,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      addConfigChartFailure({
        kpiId: payload?.data?.kpiId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* editConfigChart({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: EDIT_CONFIG_CHART_PATH,
      data: payload?.data,
    });
    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(
        editConfigChartSuccess({
          kpiId: payload?.data?.kpiId,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      editConfigChartFailure({
        kpiId: payload?.data?.kpiId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* deleteDataVisualisationChart({ payload }) {
  const { kpiId, type } = payload?.data;

  try {
    const { data } = yield call(deleteData, {
      url: `${DELETE_DATA_VISUALISATION_CHART_PATH}/${kpiId}/${type}`,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(deleteDataVisualisationChartSuccess({ kpiId, type }));

      if (payload?.callback) {
        payload?.callback(IsSuccess);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(deleteDataVisualisationChartFailure({ error: message }));

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* getDashboardUrl() {
  try {
    const { data } = yield call(getData, { url: GET_DASHBOARD_URL_PATH });
    const { IsSuccess, Data } = data;
    if (IsSuccess) {
      yield put(getDashboardUrlSuccess({ data: Data?.url }));
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      getDashboardUrlFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* updateDataVisualizationStatus({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: UPDATE_SHOW_CHART_STATUS_PATH,
      data: payload?.data,
    });
    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(
        updateShowChartStatusSuccess({
          kpiId: payload?.data?.kpiId,
          data: { isShowChart: payload?.data?.isShowChart },
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      updateShowChartStatusFailure({
        kpiId: payload?.data?.kpiId,
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* deletePerfAnalysis({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${DELETE_PERFORMANCE_ANALYSIS_PATH}/${payload?.data?.id}`,
    });

    const { IsSuccess } = data;
    if (IsSuccess) {
      yield put(
        deletePerfAnalysisSuccess({
          id: payload?.data?.id,
          kpiId: payload?.data?.kpiId,
        }),
      );
      // update updatedAt of report
      yield put(updateTimeOfReport(new Date()));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      deletePerfAnalysisFailure({
        error: responseMessage?.Message || message,
      }),
    );

    if (payload?.callback) {
      payload?.callback(false);
    }
  }
}

export function* getKPIsSameLevel({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_KPIS_SAME_LEVEL,
      params: payload.params,
    });

    const { IsSuccess, Data } = data;

    if (IsSuccess) {
      yield put(
        getKPIsSameLevelSuccess({
          prop: payload.prop,
          data: Data,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;
    yield put(
      getKPIsSameLevelFailure({
        prop: payload.prop,
        error: message,
      }),
    );
  }
}

export default function* kpiDetailSaga() {
  yield takeLatest(getKPIRequest().type, getKPI);
  yield takeLatest(updateForecastDataRequest().type, updateForecastData);
  yield takeLatest(
    addOrUpdatePerfAnalysisRequest().type,
    addOrUpdatePerfAnalysis,
  );
  yield takeLatest(deletePerfAnalysisRequest().type, deletePerfAnalysis);
  yield takeLatest(getInfoOfKpiRequest().type, getInfoOfKPI);
  yield takeLatest(getPerfAnalysisRequest().type, getPerfAnalysis);
  yield takeLatest(getBreadcrumbsRequest().type, getBreadcrumbs);
  yield takeLatest(getActivityLogRequest().type, getActivityLog);
  yield takeEvery(getKPIsByPropRequest().type, getKPIsByProp);
  yield takeEvery(getDataChartRequest().type, getDataChart);
  yield takeLatest(addConfigChartRequest().type, addConfigChart);
  yield takeLatest(editConfigChartRequest().type, editConfigChart);
  yield takeLatest(
    deleteDataVisualisationChartRequest().type,
    deleteDataVisualisationChart,
  );
  yield takeLatest(getDashboardUrlRequest().type, getDashboardUrl);
  yield takeLatest(
    updateShowChartStatusRequest().type,
    updateDataVisualizationStatus,
  );
  yield takeEvery(getKPIsSameLevelRequest().type, getKPIsSameLevel);
}
