import { delay, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { GET_UNREAD_REPORT_PATH } from '../constants/paths';

import { getData } from '../services/api';

import {
  getReportsByPropRequest,
  getReportsByPropSuccess,
  getReportsByPropFailure,
  getTotalUnreadReportRequest,
  getTotalUnreadReportSuccess,
  getTotalUnreadReportFailure,
} from '../slices/newsReport';

export function* getNewsReportsByProp(params) {
  const { url, prop, query, isLoadMore } = params?.payload || {};

  try {
    const { data } = yield call(getData, { url });
    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(
        getReportsByPropSuccess({
          prop,
          isLoadMore,
          data: Data,
          query,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(
      getReportsByPropFailure({
        prop,
        message,
      }),
    );
  }
}

export function* getTotalUnreadReport({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: GET_UNREAD_REPORT_PATH,
      params: payload?.query,
    });
    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(getTotalUnreadReportSuccess(Data?.unread));
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(getTotalUnreadReportFailure(responseMessage?.Message || message));
  }
}

export default function* newsReportSaga() {
  yield takeEvery(getReportsByPropRequest().type, getNewsReportsByProp);
  yield takeLatest(getTotalUnreadReportRequest().type, getTotalUnreadReport);
}
