/* eslint-disable camelcase */
const config = window?.config || {
  BASE_URL: 'https://dxsvh7md09j46.cloudfront.net/api',
  TENANT_ID: '8b24551d-7c2c-4beb-8b61-95f32d9929ef',
  CLIENT_ID: '40aa1971-b943-4fd1-acfa-7b78bf901912',
  CLIENT_SECRET: '2n0K-GP.ekjXU4~de~5iFube0e42bg__fJ',
  SOCIAL_SERVICE_URL:
    'https://ubrzn46x2a.execute-api.ap-southeast-1.amazonaws.com/dev/api',
  SOCIAL_SERVICE_SOCKET_URL:
    'wss://mdrx46x6c7.execute-api.ap-southeast-1.amazonaws.com/dev',
  APPLICATION_ID: '77ee9e06-37f5-4a4c-8deb-475f47df104d',
};

const qs = new URLSearchParams({
  redirect_uri: `${window.location.origin}/auth/callback`,
  client_id: config?.CLIENT_ID,
  response_type: 'id_token token',
  scope: 'openid profile',
  state: '0Csr13', // A randomly generated unique value. recommended
  nonce: 'VTTaVx', // A randomly generated unique value. required
});

const REDIRECT_TO_AUTHENTICATION = `https://login.microsoftonline.com/${
  config?.TENANT_ID
}/oauth2/v2.0/authorize?${qs.toString()}`;
const REDIRECT_TO_SIGNOUT = `https://login.microsoftonline.com/${config?.TENANT_ID}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
const HUB_NOTIFICATION_APPLICATION_URL =
  'https://staging.notificationservice.petronas.com/NotificationHub';

export default {
  BASE_URL: config?.BASE_URL,
  SOCIAL_SERVICE_URL: config?.SOCIAL_SERVICE_URL,
  SOCIAL_SERVICE_SOCKET_URL: config?.SOCIAL_SERVICE_SOCKET_URL,
  APPLICATION_ID: config?.APPLICATION_ID,
  REDIRECT_TO_AUTHENTICATION,
  REDIRECT_TO_SIGNOUT,
  HUB_NOTIFICATION_APPLICATION_URL,
};
