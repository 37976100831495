import moment from 'moment';

import { uuid } from 'services/format/string';

export const createWeekOptions = () => {
  const result = moment().isoWeeksInYear();

  const weeks = [];
  for (let index = 1; index <= result; index++) {
    weeks.push({
      id: index,
      label: `Week ${index}`,
      value: index,
    });
  }

  return weeks;
};
export const createYearOptions = () => {
  const date = new Date();
  const years = [];
  const year = date.getFullYear();

  // eslint-disable-next-line no-plusplus
  for (let i = year; i > 2017; i--) {
    years.push({
      label: i,
      value: i,
    });
  }

  return years;
};

export const createDayOptions = (month, year) => {
  const numberDay = new Date(year, month, 0).getDate();
  const days = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= numberDay; i++) {
    days.push({
      label: i,
      value: i,
    });
  }

  return days;
};

export const FORMAT_DATETIME = {
  MMMyyyy: 'MMM yyyy',
  yyyyMMdd: 'yyyy-MM-dd',
  MMM: 'MMM',
};

// Start: Date Format Constants
export const DATETIME_DAILY_FORMAT = 'dddd, DD MMMM YYYY';
export const DATETIME_LOCAL_MS = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const DATETIME_CUSTOM_FORMAT = 'DD MMMM YYYY [at] LT';
export const DATETIME_CUSTOM_FORMAT_2 = 'DD MMM YYYY LT';
export const DATETIME_CUSTOM_FORMAT_3 = 'DD MMM YYYY [at] LT';
export const DATETIME_CUSTOM_FORMAT_4 = 'D MMM YYYY [at] LT';
export const DEF_DATE_FORMAT = 'YYYY-MM-DD';
export const DEF_FULL_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const SHORT_DATE_FORMAT = 'D MMM YY';
export const SHORT_DATE_FORMAT_1 = 'd MMM yyyy';
export const SHORT_DATE_FORMAT_2 = 'D MMM YYYY';
export const SHORT_DATE_FORMAT_3 = 'DD MMM YYYY';
export const SHORT_DATE_FORMAT_4 = 'MMMM YYYY';
export const SHORT_DATE_FORMAT_5 = 'MMM YYYY';
export const FULL_DATE_FORMAT = 'D MMMM YYYY';
export const FULL_DATE_TIME_FORMAT = 'D MMMM YYYY, h:mma';
export const FULL_DATE_YEAR_FORMAT = 'D MMM YYYY';
export const FULL_DATE_YEAR_FORMAT_2 = 'D MMMM YYYY';
export const MOMENT_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const YEAR_DATE = 'YYYY/MM/DD';
export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MMM';
export const QUARTER_FORMAT = 'Q';
export const DAY_FORMAT = 'DD';
export const MONTH_FORMAT_FULL_TEXT = 'MMMM';
export const TIME_FORMAT = 'hh:mm A';
// End: Date Format

// Start: Other Constants

export const WAIT_INTERVAL = 600;
export const SCHEDULE_YEARS_FUTURE = 2;
export const SCHEDULE_YEARS_PAST = 5;

export const MONTHLY = 1;
export const MONTH_TO_DATE = -1; // not implement
export const QUARTERLY = 2;
export const QUARTERLY_TO_DATE = -2; // not implement
export const YEARLY = 8;
export const DAILY = 6;
export const WEEKLY = 7;
export const YEAR_TO_DATE = 3;
export const YEAR_TO_DATE_DAILY = -3; // not implement
export const YEAR_END_PROJECTION = 4;
export const FREQUENCY_OPTIONS = [
  {
    value: WEEKLY,
    label: 'Weekly',
  },
  {
    value: MONTHLY,
    label: 'Monthly',
  },
  {
    value: QUARTERLY,
    label: 'Quarterly',
  },
  {
    value: YEARLY,
    label: 'Yearly',
  },
];

export const FULL_FREQUENCY_OPTIONS = [
  {
    id: uuid(),
    value: DAILY,
    label: 'Daily',
  },
  {
    id: uuid(),
    value: WEEKLY,
    label: 'Weekly',
  },
  {
    id: uuid(),
    value: MONTHLY,
    label: 'Monthly',
  },
  {
    id: uuid(),
    value: QUARTERLY,
    label: 'Quarterly',
  },
  {
    id: uuid(),
    value: YEARLY,
    label: 'Yearly',
  },
  {
    id: uuid(),
    value: YEAR_TO_DATE,
    label: 'Year To Date',
  },
  {
    id: uuid(),
    value: YEAR_END_PROJECTION,
    label: 'Year End Projection',
  },
];

export const FREQUENCY_OPTIONS_MODAL = [
  {
    id: uuid(),
    value: DAILY,
    label: 'Daily',
    subLabel: 'Daily',
  },
  {
    id: uuid(),
    value: WEEKLY,
    label: 'Weekly',
    subLabel: 'Weekly',
  },
  {
    id: uuid(),
    value: MONTHLY,
    label: 'Monthly',
    subLabel: 'Monthly',
    shortLabel: 'monthly',
  },
  {
    id: uuid(),
    value: MONTH_TO_DATE,
    label: 'Month To Date (MTD)',
    subLabel: 'Daily',
    shortLabel: 'mtd',
  },
  {
    id: uuid(),
    value: QUARTERLY,
    label: 'Quarterly',
    subLabel: 'Quarterly',
    shortLabel: 'quarterly',
  },
  {
    id: uuid(),
    value: QUARTERLY_TO_DATE,
    label: 'Quarter To Date (QTD)',
    subLabel: 'Daily',
    shortLabel: 'qtd',
  },
  {
    id: uuid(),
    value: YEAR_TO_DATE_DAILY,
    label: 'Year To Date (Daily)',
    subLabel: 'Daily',
    shortLabel: 'ytd_daily',
  },
  {
    id: uuid(),
    value: YEAR_TO_DATE,
    label: 'Year To Date (Month)',
    subLabel: 'Year To Date (Month)',
    shortLabel: 'ytd',
  },
  {
    id: uuid(),
    value: YEAR_END_PROJECTION,
    label: 'Year End Projection',
    subLabel: 'Year End Projection',
    shortLabel: 'yep',
  },
];

export const WEEK_OPTIONS = createWeekOptions();
export const MONTH_OPTIONS = [
  {
    id: uuid(),
    label: 'January',
    value: 1,
  },
  {
    id: uuid(),
    label: 'February',
    value: 2,
  },
  {
    id: uuid(),
    label: 'March',
    value: 3,
  },
  {
    id: uuid(),
    label: 'April',
    value: 4,
  },
  {
    id: uuid(),
    label: 'May',
    value: 5,
  },
  {
    id: uuid(),
    label: 'June',
    value: 6,
  },
  {
    id: uuid(),
    label: 'July',
    value: 7,
  },
  {
    id: uuid(),
    label: 'August',
    value: 8,
  },
  {
    id: uuid(),
    label: 'September',
    value: 9,
  },
  {
    id: uuid(),
    label: 'October',
    value: 10,
  },
  {
    id: uuid(),
    label: 'November',
    value: 11,
  },
  {
    id: uuid(),
    label: 'December',
    value: 12,
  },
];
export const QUARTER_OPTIONS = [
  {
    id: uuid(),
    label: 'Q1',
    value: 1,
  },
  {
    id: uuid(),
    label: 'Q2',
    value: 2,
  },
  {
    id: uuid(),
    label: 'Q3',
    value: 3,
  },
  {
    id: uuid(),
    label: 'Q4',
    value: 4,
  },
];
export const YEAR_OPTIONS = createYearOptions();

export const MONTH_INITIAL_VALUE = MONTH_OPTIONS[0];
export const YEAR_INITIAL_VALUE = {
  label: new Date().getFullYear(),
  value: new Date().getFullYear(),
};

export const FREQUENCY_NAME = {
  [DAILY]: 'Daily',
  [WEEKLY]: 'Weekly',
  [MONTHLY]: 'Monthly',
  [MONTH_TO_DATE]: 'Month To Date',
  [QUARTERLY]: 'Quarterly',
  [QUARTERLY_TO_DATE]: 'Quarterly To Date',
  [YEAR_TO_DATE_DAILY]: 'Year To Date',
  [YEAR_TO_DATE]: 'Year To Date',
  [YEAR_END_PROJECTION]: 'Year End Projection',
  [YEARLY]: 'Yearly',
};

export const FREQUENCY_DATA_VISUALIZATION = [
  {
    id: uuid(),
    label: 'Monthly',
    value: MONTHLY,
  },
  {
    id: uuid(),
    label: 'YTD',
    value: YEAR_TO_DATE,
  },
];

export const SIMULATION_START_DATE = 0;
export const SIMULATION_END_DATE = 1;
// Start: End Constants
