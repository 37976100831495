import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useUsersAvatar from '../../../hooks/useUsersAvatar';

import { gettingNameInitials } from '../../../services/format/string';
import { arrayBufferToBase64 } from '../../../services/format/file';
import {
  setStorage,
  getStorage,
  removeStorage,
} from '../../../services/storage';

import './style.scss';

const BASE64_URL = 'data:image/jpeg;base64,';
const HAS_SOURCE = true;
// const HAS_NO_SOURCE = false;
const AVATARS_STORAGE_KEY = 'AVATARS';
const SHAPE_CLASSNAME = {
  circle: 'pvda-avatar--circle',
  square: 'pvda-avatar--square',
};
const SIZE_CLASSNAME = {
  default: 'pvda-avatar--default',
  large: 'pvda-avatar--lg',
  sm: 'pvda-avatar--sm',
};

const Avatar = ({
  children,
  icon,
  shape,
  size,
  className,
  src,
  url,
  name,
  style,
}) => {
  const [imageData, setImageData] = useState('');

  const { usersAvatar, onAddUsersAvatar } = useUsersAvatar();

  const styleClasses = [
    'pvda-avatar',
    SHAPE_CLASSNAME[shape],
    SIZE_CLASSNAME[size],
    className,
  ];

  useEffect(() => {
    if (src) {
      setImageData(`${BASE64_URL}${src}`);
    } else if (usersAvatar[url]) {
      setImageData(`${BASE64_URL}${usersAvatar[url]}`);
    } else {
      setImageData('');
    }
  }, [src, url, usersAvatar[url]]);

  useEffect(() => {
    const avatarsStorage = getStorage(AVATARS_STORAGE_KEY);
    let cancelRequest = false;

    if (Object.keys(usersAvatar).length <= 0) {
      removeStorage(AVATARS_STORAGE_KEY);
    }
    if (url) {
      const fetchData = async () => {
        if (!avatarsStorage || !avatarsStorage[url]) {
          setStorage({
            key: AVATARS_STORAGE_KEY,
            val: avatarsStorage
              ? {
                  ...avatarsStorage,
                  [url]: HAS_SOURCE,
                }
              : { [url]: HAS_SOURCE },
          });

          try {
            const response = await fetch(url);
            const data = await response.arrayBuffer();
            const base64Data =
              data.byteLength > 0 ? arrayBufferToBase64(data) : '';

            if (cancelRequest) {
              return;
            }

            onAddUsersAvatar({
              key: url,
              data: base64Data,
            });
          } catch {
            if (cancelRequest) {
              return;
            }
          }
        }
      };

      fetchData();
    }

    return () => {
      cancelRequest = true;
    };
  }, [url]);

  let avatarDisplay = (
    <span
      className="pvda-avatar--string"
      style={{ transform: 'scale(1) translateX(-50%)' }}
    >
      {children}
    </span>
  );

  if (icon) {
    avatarDisplay = (
      <span>
        <img alt="Avatar Icon" src={icon} />
      </span>
    );
    styleClasses.push('pvda-avatar--icon');
  } else if (imageData) {
    avatarDisplay = <img alt={name} src={imageData} />;
    styleClasses.push('pvda-avatar--image');
  } else if (name) {
    avatarDisplay = <span>{gettingNameInitials(name)}</span>;
    styleClasses.push('pvda-avatar--string');
  }

  return (
    <span className={styleClasses.join(' ')} style={style}>
      {avatarDisplay}
    </span>
  );
};

Avatar.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  shape: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
};

Avatar.defaultProps = {
  children: null,
  icon: null,
  shape: 'circle',
  size: 'default',
  className: '',
  src: '',
  url: '',
  name: '',
  style: {},
};

export default memo(Avatar);
