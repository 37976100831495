import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import useViewReport from 'hooks/useViewReport';

import { getGreetingTime } from '../../../services/format/date';

import { MY_FEED_LOADING } from '../../../constants/loadingTypes';

import SkeletonLoading from '../../skeleton/SkeletonLoading';
import ScrollMore from '../../core/ScrollMore';
import FeedReportCard from '../FeedReportCard';

import './styles.scss';

const MyFeed = ({ reports, userInfo, opuActivated, onScrollMore }) => {
  const { onViewReportMyFeed: onViewReport } = useViewReport();

  return (
    <div className="pvda-my-feed">
      <div className="pvda-my-feed__header">
        <div
          style={{
            fontWeight: 500,
            fontSize: 11,
            lineHeight: '120%',
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#8879C9',
            marginTop: 8,
          }}
        >
          Workspace
        </div>
        <div
          style={{
            fontWeight: 'normal',
            fontSize: 24,
            lineHeight: '32px',
            color: '#FFFFFF',
            marginTop: 24,
          }}
        >
          <div>{`Good ${getGreetingTime()}, `}</div>
          <div>{userInfo?.fullName || 'Admin'}</div>
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 'normal',
            color: '#FFFFFF',
            margin: '8px 0px',
          }}
        >
          {`${reports?.data?.totalCount} reports being updated in ${
            opuActivated?.name
          } this ${getGreetingTime()}`}
        </div>
      </div>
      <div className="pvda-my-feed__content">
        <div
          style={{
            fontWeight: 'normal',
            fontSize: 24,
            color: '#3F3C4C',
            lineHeight: '32px',
          }}
        >
          My Feed
        </div>
        <Tabs
          defaultActiveKey="all-updates"
          transition={false}
          className="pvda-b-tabs"
        >
          <Tab
            eventKey="all-updates"
            title="All updates"
            className="pvda-my-feed__tab"
          >
            <ScrollMore
              className="pvda-my-feed__tab-content"
              onScrollMore={onScrollMore}
            >
              <SkeletonLoading
                isLoading={reports?.status === 'loading'}
                type={MY_FEED_LOADING}
                counter={4}
              >
                {reports?.data?.items?.map((report, index) => (
                  <FeedReportCard
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${report.id}${report.actionId}${index}`}
                    report={report}
                    onViewReport={onViewReport}
                  />
                ))}
              </SkeletonLoading>
            </ScrollMore>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

MyFeed.propTypes = {
  reports: PropTypes.object,
  userInfo: PropTypes.object,
  opuActivated: PropTypes.object,
  onScrollMore: PropTypes.func,
};

MyFeed.defaultProps = {
  reports: {},
  userInfo: {},
  opuActivated: {},
  onScrollMore() {},
};

export default memo(MyFeed);
