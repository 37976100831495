import React, { useState, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { BiChevronDown } from 'react-icons/bi';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

import { FaRegClock, FaCircle } from 'react-icons/fa';

import {
  PROCESS_BAR_REPORT_STATUS,
  REPORT_STATUS,
} from 'constants/reportStatus';
import { CATEGORY_NOTIFICATION_REQUEST_ACCESS_TYPE } from 'features/EPIC/constants/categoryRequestAccess';

import { getLastTimeUpdated } from 'services/format/date';

import HorizontalProgressBar from '../../core/HorizontalProgressBar';
import UserInfoAvatar from '../UserInfoAvatar';

import './styles.scss';

const CLARIFIED_TYPE = 'Clarified';

const FeedReportCard = ({ report, onViewReport }) => {
  const [isExpandContent, setIsExpandContent] = useState(false);

  const history = useHistory();

  const styleClasses = ['pvda-feed-report-card'];
  if (isExpandContent) {
    styleClasses.push('pvda-feed-report-card--expand ');
  }

  const {
    id,
    actionId,
    url,
    type,
    opu,
    name,
    title,
    message,
    remark,
    createdDate,
    isRead,
    isCategoryNoti,
    progressBar,
  } = report;

  return (
    <div className={styleClasses.join(' ')}>
      <div className="pvda-feed-report-card__opu">{opu?.name}</div>
      <div className="pvda-feed-report-card__title">{name}</div>
      <div className="pvda-feed-report-card__name">{title}</div>
      {!isCategoryNoti && (
        <div className="pvda-feed-report-card__description">{message}</div>
      )}
      {isCategoryNoti ? (
        type !== CATEGORY_NOTIFICATION_REQUEST_ACCESS_TYPE.REJECT && (
          <Button
            className="pvda-feed-report-card__btn-view-report"
            variant="custom-primary"
            onClick={() => history.push(url)}
          >
            View Category
          </Button>
        )
      ) : (
        <Button
          className="pvda-feed-report-card__btn-view-report"
          variant="custom-primary"
          onClick={() =>
            onViewReport({
              reportId: id,
              actionId,
              url: type === CLARIFIED_TYPE ? url : null,
            })
          }
        >
          View report
        </Button>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '14px',
        }}
      >
        <div className="pvda-feed-report-card__updated-date">
          <FaRegClock color="#C4C3D1" size={12} />
          {getLastTimeUpdated(createdDate)}
        </div>
        {!isCategoryNoti && (
          <BiChevronDown
            className="pvda-feed-report-card__drop-down-icon"
            color={`${isExpandContent ? '#3F3C4C' : '#C4C3D1'}`}
            onClick={() => setIsExpandContent(!isExpandContent)}
          />
        )}
      </div>
      {isExpandContent && (
        <>
          <div className="pvda-feed-report-card__progress">
            <div className="pvda-feed-report-card__progress-header">
              <div
                style={{
                  color: '#787587',
                  fontSize: 14,
                }}
              >
                Progress
              </div>
              <hr
                style={{
                  flex: 1,
                  marginLeft: 4,
                }}
              />
            </div>
            <div className="pvda-feed-report-card__progress-body">
              <FaCircle
                color="#9F79B7"
                size={6}
                style={{
                  marginBottom: 5,
                  marginRight: 16,
                }}
              />
              <HorizontalProgressBar
                currentTask={
                  REPORT_STATUS[progressBar?.status?.id]?.currentTask
                }
                tasks={PROCESS_BAR_REPORT_STATUS}
              />
            </div>
          </div>
          {!isEmpty(remark) && (
            <div className="pvda-feed-report-card__remarks">
              <div className="pvda-feed-report-card__remarks-header">
                <div
                  style={{
                    color: '#787587',
                    fontSize: 14,
                  }}
                >
                  Review remarks
                </div>
                <hr
                  style={{
                    flex: 1,
                    marginLeft: 4,
                  }}
                />
              </div>
              <div className="pvda-feed-report-card__remarks-body">
                <div className="pvda-feed-report-card__remarks-body__message">
                  {remark?.remarks}
                </div>
                <div className="pvda-feed-report-card__stakeholder-info">
                  <UserInfoAvatar userInfo={remark.userInfo} avatarSize="sm" />
                  <div style={{ marginLeft: 4 }} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        color: '#6559AA',
                        fontSize: 14,
                      }}
                    >
                      {remark?.userInfo?.fullName}
                    </div>
                    <div
                      style={{
                        color: '#9F9EAE',
                        fontSize: 11,
                      }}
                    >
                      {remark?.userInfo?.position}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {!isRead && (
        <div className="pvda-feed-report-card__status">
          <FaCircle color="#FCAA1B" size={8} />
        </div>
      )}
    </div>
  );
};

FeedReportCard.propTypes = {
  report: PropTypes.object,
  onViewReport: PropTypes.func,
};

FeedReportCard.defaultProps = {
  report: {},
  onViewReport() {},
};

export default memo(FeedReportCard);
