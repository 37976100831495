import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';

import { updateIsShowLeftBar } from 'slices/user';

import LogoMark from '../../../assets/images/logo-mark.png';
import WordMark from '../../../assets/images/word-mark.png';

import OPUMenu from '../OPUMenu';

import './styles.scss';

const LeftSidebar = ({ opus, opuActivated, onSetOPUActivated }) => {
  const [isSideBarExpand, setSideBarExpand] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const clickExpand = () => {
    setSideBarExpand(!isSideBarExpand);
    dispatch(updateIsShowLeftBar(!isSideBarExpand));
  };

  return (
    <div
      className={`pvda-left-sidebar pvda-left-sidebar--${
        isSideBarExpand ? 'expand' : 'default'
      }`}
    >
      <header className="pvda-left-sidebar__header">
        <div
          aria-hidden
          className="pvda-left-sidebar__logo"
          onClick={() => {
            history.push('/');
          }}
        >
          <img
            className="pvda-left-sidebar__logo-mark"
            alt="Log mark"
            src={LogoMark}
          />
          <img
            className="pvda-left-sidebar__word-mark"
            alt="Word mark"
            src={WordMark}
          />
        </div>
        <div
          className="pvda-left-sidebar__btn-expand"
          aria-hidden
          onClick={clickExpand}
        >
          <BiChevronLeft color="#50A4B9" />
        </div>
      </header>
      <section className="pvda-left-sidebar__content">
        <OPUMenu
          isSideBarExpand={isSideBarExpand}
          opus={opus}
          opuActivated={opuActivated}
          onSetOPUActivated={onSetOPUActivated}
        />
      </section>
    </div>
  );
};

LeftSidebar.propTypes = {
  opus: PropTypes.array,
  opuActivated: PropTypes.object,
  onSetOPUActivated: PropTypes.func,
};

LeftSidebar.defaultProps = {
  opus: [],
  opuActivated: {},
  onSetOPUActivated() {},
};

export default memo(LeftSidebar);
