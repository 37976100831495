/**
 *
 * PinnedCategoriesLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './style.scss';

const PinnedCategoriesLoading = ({ counter }) =>
  Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-category-loading-item">
      <Placeholder as="p" animation="glow">
        <Placeholder xs={4} size="xs" />
        <Placeholder xs={10} size="lg" bg="custom-primary" />
        <Placeholder xs={12} size="sm" />
      </Placeholder>
    </div>
  ));

PinnedCategoriesLoading.propTypes = { counter: PropTypes.number };

PinnedCategoriesLoading.defaultProps = { counter: 0 };

export default memo(PinnedCategoriesLoading);
