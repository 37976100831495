import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Typography = ({
  component,
  type,
  color,
  className,
  'aria-label': ariaLabel,
  children,
  ...rest
}) => {
  const Component = component;
  const componentClassName = classNames(type, color, className);

  return (
    <Component className={componentClassName} aria-label={ariaLabel} {...rest}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  component: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  'aria-label': PropTypes.string,
  children: PropTypes.any,
};

Typography.defaultProps = {
  component: 'span',
  type: 'body-normal',
  color: 'emphasis-body-n800',
  className: '',
  'aria-label': 'ariaLabel',
  children: null,
};

export default memo(Typography);
