import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from 'react-bootstrap/ProgressBar';

import './styles.scss';

const HorizontalProgressBar = ({ currentTask, tasks }) => (
  <div className="pvda-horizontal-progress-bar">
    <div className="pvda-horizontal-progress-bar__tasks">
      <ProgressBar
        max={tasks.length || 100}
        now={currentTask}
        variant="horizontal-progress-bar"
        visuallyHidden
        style={{
          width: '100%',
          maxHeight: '8px',
        }}
      />
      <span
        style={{
          fontSize: 12,
          color: '#9F9EAE',
          marginLeft: '0.5rem',
          lineHeight: '160%',
        }}
      >
        {`${currentTask}/${tasks.length}`}
      </span>
    </div>
    <div className="pvda-horizontal-progress-bar__label">
      Current task: {tasks[currentTask - 1]?.name}
    </div>
  </div>
);

HorizontalProgressBar.propTypes = {
  currentTask: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tasks: PropTypes.array,
};

HorizontalProgressBar.defaultProps = {
  currentTask: 0,
  tasks: [],
};

export default memo(HorizontalProgressBar);
