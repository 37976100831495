import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'function';
const initialState = {
  functions: {
    status: 'idle',
    data: [],
    error: null,
  },
  functionsInReport: {
    status: 'idle',
    data: [],
    error: null,
  },
  functionsByOPU: {
    status: 'idle',
    data: [],
    error: null,
  },
  suggestFunctions: {
    status: 'idle',
    data: [],
    error: null,
  },
  addFunctions: {
    status: 'idle',
    error: null,
  },
  deleteFunctions: {
    status: 'idle',
    data: {},
    error: null,
  },
  updateSequenceFunction: {
    status: 'idle',
    error: null,
  },
  manageAttachments: {
    status: 'idle',
    error: null,
  },
  deleteFunctionAttachment: {
    status: 'idle',
    error: null,
  },
  addNewFunctions: {
    status: 'idle',
    data: [],
    error: null,
  },
  addOrUpdateExecutiveSummary: {
    status: 'idle',
    data: [],
    error: null,
  },
};

const functionSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getFunctionsRequest(state) {
      state.functions.status = 'loading';
    },
    getFunctionsSuccess(state, action) {
      state.functions.data = action.payload;
      state.functions.status = 'succeeded';
      state.functions.error = null;
    },
    getFunctionsFailure(state, action) {
      state.functions.status = 'failed';
      state.functions.data = {};
      state.functions.error = action.payload.error;
    },
    getSuggestFunctionsRequest(state) {
      state.suggestFunctions.status = 'loading';
    },
    getSuggestFunctionsSuccess(state, action) {
      state.suggestFunctions.data = action.payload;
      state.suggestFunctions.status = 'succeeded';
      state.suggestFunctions.error = null;
    },
    getSuggestFunctionsFailure(state, action) {
      state.suggestFunctions.status = 'failed';
      state.suggestFunctions.data = [];
      state.suggestFunctions.error = action.payload.error;
    },
    getFunctionsInReportRequest(state) {
      state.functionsInReport.status = 'loading';
    },
    getFunctionsInReportSuccess(state, action) {
      state.functionsInReport.data = action.payload;
      state.functionsInReport.status = 'succeeded';
      state.functionsInReport.error = null;
    },
    getFunctionsInReportFailure(state, action) {
      state.functionsInReport.status = 'failed';
      state.functionsInReport.data = [];
      state.functionsInReport.error = action.payload.error;
    },
    getFunctionsByOPURequest(state) {
      state.functionsByOPU.status = 'loading';
    },
    getFunctionsByOPUSuccess(state, action) {
      state.functionsByOPU.data = action.payload;
      state.functionsByOPU.status = 'succeeded';
      state.functionsByOPU.error = null;
    },
    getFunctionsByOPUFailure(state, action) {
      state.functionsByOPU.status = 'failed';
      state.functionsByOPU.data = [];
      state.functionsByOPU.error = action.payload.error;
    },
    addFunctionsRequest(state) {
      state.addFunctions.status = 'loading';
    },
    addFunctionsSuccess(state, action) {
      state.functionsInReport.data = action.payload;
      state.addFunctions.status = 'succeeded';
      state.addFunctions.error = null;
    },
    addFunctionsFailure(state, action) {
      state.addFunctions.status = 'failed';
      state.addFunctions.error = action.payload.error;
    },
    deleteFunctionsRequest(state) {
      state.deleteFunctions.status = 'loading';
    },
    deleteFunctionsSuccess(state, action) {
      state.functionsInReport.data = state.functionsInReport.data.filter(
        kpiCategory => kpiCategory.id !== action.payload.functionId,
      );
      state.deleteFunctions.status = 'succeeded';
      state.deleteFunctions.error = null;
    },
    deleteFunctionsFailure(state, action) {
      state.deleteFunctions.status = 'failed';
      state.deleteFunctions.data = {};
      state.deleteFunctions.error = action.payload;
    },
    updateSequenceFunctionRequest(state) {
      state.updateSequenceFunction.status = 'loading';
    },
    updateSequenceFunctionSuccess(state, action) {
      state.functionsInReport.data = action.payload.updatedFunctionsKPIs;
      state.updateSequenceFunction.status = 'succeeded';
      state.updateSequenceFunction.error = null;
    },
    updateSequenceFunctionFailure(state, action) {
      state.updateSequenceFunction.status = 'failed';
      state.updateSequenceFunction.error = action.payload;
    },
    manageAttachmentsRequest(state) {
      state.manageAttachments.status = 'loading';
    },
    manageAttachmentsSuccess(state, action) {
      state.functionsInReport.data = state.functionsInReport.data.map(func =>
        func.id === action.payload.id ? action.payload : func,
      );
      state.manageAttachments.status = 'succeeded';
      state.manageAttachments.error = null;
    },
    manageAttachmentsFailure(state, action) {
      state.manageAttachments.status = 'failed';
      state.manageAttachments.error = action.payload.error;
    },
    addNewFunctionsRequest(state) {
      state.addNewFunctions.status = 'loading';
    },
    addNewFunctionsSuccess(state) {
      state.addNewFunctions.status = 'succeeded';
      state.addNewFunctions.error = null;
    },
    addNewFunctionsFailure(state, action) {
      state.addNewFunctions.status = 'failed';
      state.addNewFunctions.error = action.payload.error;
    },
    deleteFunctionAttachmentRequest(state) {
      state.deleteFunctionAttachment.status = 'loading';
    },
    deleteFunctionAttachmentSuccess(state, action) {
      const { type, attachmentId, functionId } = action.payload;
      const functionInReport = state.functionsInReport.data.find(
        func => func.id === functionId,
      );
      functionInReport[type] = functionInReport[type].filter(
        attachment => attachment.id !== attachmentId,
      );

      state.deleteFunctionAttachment.status = 'succeeded';
      state.deleteFunctionAttachment.error = null;
    },
    deleteFunctionAttachmentFailure(state, action) {
      state.deleteFunctionAttachment.status = 'failed';
      state.deleteFunctionAttachment.error = action.payload;
    },
    addOrUpdateExecutiveSummaryRequest(state) {
      state.addOrUpdateExecutiveSummary.status = 'loading';
    },
    addOrUpdateExecutiveSummarySuccess(state) {
      state.addOrUpdateExecutiveSummary.status = 'succeeded';
      state.addOrUpdateExecutiveSummary.error = null;
    },
    addOrUpdateExecutiveSummaryFailure(state, action) {
      state.addOrUpdateExecutiveSummary.status = 'failed';
      state.addOrUpdateExecutiveSummary.error = action.error;
    },
  },
});

export const {
  getFunctionsRequest,
  getFunctionsSuccess,
  getFunctionsFailure,
  getFunctionsInReportRequest,
  getFunctionsInReportSuccess,
  getFunctionsInReportFailure,
  getFunctionsByOPURequest,
  getFunctionsByOPUSuccess,
  getFunctionsByOPUFailure,
  getSuggestFunctionsRequest,
  getSuggestFunctionsSuccess,
  getSuggestFunctionsFailure,
  addFunctionsRequest,
  addFunctionsSuccess,
  addFunctionsFailure,
  deleteFunctionsRequest,
  deleteFunctionsSuccess,
  deleteFunctionsFailure,
  updateSequenceFunctionRequest,
  updateSequenceFunctionSuccess,
  updateSequenceFunctionFailure,
  manageAttachmentsRequest,
  manageAttachmentsSuccess,
  manageAttachmentsFailure,
  addNewFunctionsRequest,
  addNewFunctionsSuccess,
  addNewFunctionsFailure,
  deleteFunctionAttachmentRequest,
  deleteFunctionAttachmentSuccess,
  deleteFunctionAttachmentFailure,
  addOrUpdateExecutiveSummaryRequest,
  addOrUpdateExecutiveSummarySuccess,
  addOrUpdateExecutiveSummaryFailure,
} = functionSlice.actions;
export default functionSlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const functionsSelector = createSelector(
  rootSelector,
  ({ functions }) => functions,
);
export const functionsInReportSelector = createSelector(
  rootSelector,
  ({ functionsInReport }) => functionsInReport,
);
export const functionsByOPUSelector = createSelector(
  rootSelector,
  ({ functionsByOPU }) => functionsByOPU,
);
export const suggestFunctionsSelector = createSelector(
  rootSelector,
  ({ suggestFunctions }) => suggestFunctions,
);
export const addFunctionsSelector = createSelector(
  rootSelector,
  ({ addFunctions }) => addFunctions,
);
export const deleteFunctionsSelector = createSelector(
  rootSelector,
  ({ deleteFunctions }) => deleteFunctions,
);
export const updateSequenceFunctionSelector = createSelector(
  rootSelector,
  ({ updateSequenceFunction }) => updateSequenceFunction,
);
export const manageAttachmentsSelector = createSelector(
  rootSelector,
  ({ manageAttachments }) => manageAttachments,
);
export const addNewFunctionsSelector = createSelector(
  rootSelector,
  ({ addNewFunctions }) => addNewFunctions,
);
