import { put, delay, call, takeEvery, takeLatest } from 'redux-saga/effects';

import { deleteData, getData, postData } from '../services/api';
import { convertObjectToParams } from '../services/format/uri';

import {
  GET_CATEGORIES_PATH,
  ADD_NEW_CATEGORIES_PATH,
  UPDATE_PINNED_CATEGORIES_PATH,
  SUGGEST_KEYWORD_CATEGORIES_PATH,
  DELETE_CATEGORIES_PATH,
  GET_CATEGORIES_DETAIL,
} from '../constants/paths';

import {
  getCategoriesRequest,
  getCategoriesFailure,
  getCategoriesSuccess,
  addOrUpdateCategoryRequest,
  addOrUpdateCategorySuccess,
  addOrUpdateCategoryFailure,
  updatePinnedCategoriesRequest,
  updatePinnedCategoriesSuccess,
  updatePinnedCategoriesFailure,
  getSuggestKeywordRequest,
  getSuggestKeywordSuccess,
  getSuggestKeywordFailure,
  deleteUnpinCategoryRequest,
  deleteUnpinCategorySuccess,
  deleteUnpinCategoryFailure,
  getCategoryDetailRequest,
  getCategoryDetailSuccess,
  getCategoryDetailFailure,
} from '../slices/category';

export function* getCategories(params) {
  const { prop, query, isLoadMore } = params?.payload || {};

  try {
    const { data } = yield call(getData, {
      url: `${GET_CATEGORIES_PATH}${convertObjectToParams(query)}`,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(
        getCategoriesSuccess({
          prop,
          isLoadMore,
          data: Data,
          query,
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(
      getCategoriesFailure({
        prop,
        error: message,
      }),
    );
  }
}

export function* addOrUpdateCategory({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: ADD_NEW_CATEGORIES_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(addOrUpdateCategorySuccess());

      if (payload?.callback) {
        payload?.callback(true, payload?.data?.id);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false, payload?.data?.id);
    }

    yield put(
      addOrUpdateCategoryFailure({
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* updatePinnedCategories({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: UPDATE_PINNED_CATEGORIES_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(updatePinnedCategoriesSuccess());

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      updatePinnedCategoriesFailure({
        error: responseMessage?.Message || message,
      }),
    );
  }
}

export function* getSuggestKeyword({ payload }) {
  try {
    const { data } = yield call(getData, {
      url: SUGGEST_KEYWORD_CATEGORIES_PATH,
      params: payload,
    });

    const { IsSuccess, StatusCode, Data = [] } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(400);
      yield put(
        getSuggestKeywordSuccess(
          Data.map(item => ({
            ...item,
            value: item?.id,
            label: item?.displayName,
          })),
        ),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getSuggestKeywordFailure({ error: message }));
  }
}

export function* deleteUnpinCategory({ payload }) {
  const { categoryId, callback } = payload?.data;

  try {
    const { data } = yield call(deleteData, {
      url: `${DELETE_CATEGORIES_PATH}/${categoryId}`,
    });

    const { IsSuccess, StatusCode, Data = [] } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(deleteUnpinCategorySuccess(Data));

      if (callback) {
        callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(deleteUnpinCategoryFailure({ error: message }));

    if (callback) {
      callback(false);
    }
  }
}

export function* getCategoryDetail({ payload }) {
  const { categoryId } = payload;

  try {
    const { data } = yield call(getData, {
      url: `${GET_CATEGORIES_DETAIL}/${categoryId}`,
    });

    const { IsSuccess, StatusCode, Data = [] } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(getCategoryDetailSuccess(Data));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getCategoryDetailFailure({ error: message }));
  }
}

export default function* categorySaga() {
  yield takeEvery(getCategoriesRequest().type, getCategories);
  yield takeEvery(addOrUpdateCategoryRequest().type, addOrUpdateCategory);
  yield takeEvery(updatePinnedCategoriesRequest().type, updatePinnedCategories);
  yield takeLatest(getSuggestKeywordRequest().type, getSuggestKeyword);
  yield takeLatest(deleteUnpinCategoryRequest().type, deleteUnpinCategory);
  yield takeLatest(getCategoryDetailRequest().type, getCategoryDetail);
}
