/**
 *
 * ReportCreationHeaderLoading
 *
 */
import React, { memo } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ReportCreationHeaderLoading = () => (
  <div className="pvda-report-creation-header-loading">
    <Placeholder as="p" animation="glow">
      <Placeholder xs={3} style={{ height: 12 }} />
      <Placeholder
        xs={7}
        style={{
          height: 40,
          marginTop: 12,
          display: 'block',
        }}
      />
      <Placeholder
        xs={3}
        style={{
          height: 18,
          marginTop: 5,
        }}
      />
    </Placeholder>
  </div>
);

ReportCreationHeaderLoading.propTypes = {};

ReportCreationHeaderLoading.defaultProps = {};

export default memo(ReportCreationHeaderLoading);
