import devSettings from './dev';
import stagSettings from './stag';
import prodSettings from './prod';
import qaSettings from './qa';

// eslint-disable-next-line import/no-mutable-exports
let settings = {};
switch (process.env.NODE_ENV) {
  case 'production':
    settings = prodSettings;
    break;
  case 'staging':
    settings = stagSettings;
    break;
  case 'qa':
    settings = qaSettings;
    break;
  default:
    settings = devSettings;
    break;
}

export default settings;
