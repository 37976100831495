import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SubMenuItem from '../SubMenuItem';
import './styles.scss';

const SubMenus = ({ data = [], onSetOPUActivated }) => (
    <div
      className={classNames({
        'sidebar-menu__sub-menus': true,
      })}
    >
      {data.map(menu => (
          <React.Fragment key={menu.id}>
            <SubMenuItem
              menu={menu}
              onSetOPUActivated={onSetOPUActivated}
            />
          </React.Fragment>
        ))}
    </div>
  );

SubMenus.propTypes = {
  data: PropTypes.array,
  onSetOPUActivated: PropTypes.func,
};

SubMenus.defaultProps = {
  data: [],
  onSetOPUActivated() {},
};

export default memo(SubMenus);
