/* eslint-disable no-console */
import { addNewClarification, addMessageUnread } from 'slices/clarification';
import {
  connectSocketRequest,
  connectSocketSuccess,
  disconnectSocket,
  pingToSocket,
  subscribeGroupRequest,
  subscribeNotificationRequest,
  unsubscribeGroupRequest,
} from 'slices/socket';
import { notifyNewsReport, notifyReport } from 'slices/newsReport';
import { newActivityLog, getKPIsInFuncRequest } from 'slices/kpi';

import { getStorage } from 'services/storage';

import {
  NOTIFICATION_TYPE,
  RELOAD_LIMITED,
  SOCKET_RESPONSE_TYPES,
} from 'constants/sockets';
import { IN_PROGRESS_REPORTS, PENDING_REPORTS } from 'constants/props';
import { ACCESS_TOKEN } from 'constants/storageKeys';

import settings from '../settings';

const socketMiddleware = () => {
  let socket = null;
  let reloadCount = 0;

  const onOpen = store => () => {
    console.log('Socket is connected');
    store.dispatch(connectSocketSuccess());
  };
  const onMessage =
    store =>
    ({ data }) => {
      const dataObject = JSON.parse(data);
      const { type, data: result } = dataObject;

      switch (type) {
        case SOCKET_RESPONSE_TYPES.CREATED_MESSAGE: {
          if (window.location?.pathname?.includes(result?.payload?.kpiId)) {
            const userName = store.getState().user?.userInfo?.data?.userName;

            store.dispatch(addNewClarification(result));
            userName !== result.createdBy && store.dispatch(addMessageUnread());
          }
          break;
        }
        case SOCKET_RESPONSE_TYPES.NOTIFICATION: {
          const { notificationType, reportId } = result;

          switch (notificationType) {
            case NOTIFICATION_TYPE.NOTIFICATION:
              store.dispatch(notifyReport(result?.data));
              break;
            case NOTIFICATION_TYPE.PENDING:
              window.location?.pathname === '/' &&
                store.dispatch(
                  notifyNewsReport({
                    props: PENDING_REPORTS,
                    data: result?.data,
                  }),
                );
              break;
            case NOTIFICATION_TYPE.PROGRESS:
              window.location?.pathname === '/' &&
                store.dispatch(
                  notifyNewsReport({
                    props: IN_PROGRESS_REPORTS,
                    data: result?.data,
                  }),
                );
              break;
            case NOTIFICATION_TYPE.HISTORY:
              window.location?.pathname?.includes(
                `/performance-analysis/${reportId}/`,
              ) && store.dispatch(newActivityLog(result?.data));
              break;
            case NOTIFICATION_TYPE.KPI_REFRESH:
              store.dispatch(
                getKPIsInFuncRequest({
                  query: {
                    functionId: result?.data?.functionId,
                  },
                }),
              );
              break;
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    };
  const onClose = store => () => {
    console.log('Socket close');

    if (socket) {
      socket.close();
      socket = null;
    }

    if (reloadCount < RELOAD_LIMITED) {
      reloadCount += 1;
      store.dispatch(connectSocketRequest({ isReload: true }));
    } else {
      store.dispatch(disconnectSocket());
    }
  };
  const OnError = error => {
    console.log(error);
  };

  return store => next => action => {
    const { type, payload } = action;

    switch (type) {
      case connectSocketRequest.type: {
        if (socket) {
          return;
        }

        if (payload?.isReload) {
          reloadCount = 0;
        }

        socket = new WebSocket(
          `${settings.SOCIAL_SERVICE_SOCKET_URL}?applicationId=${
            settings.APPLICATION_ID
          }&token=Bearer ${getStorage(ACCESS_TOKEN)}`,
        );

        socket.onopen = onOpen(store);
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onerror = OnError;

        break;
      }
      case disconnectSocket.type:
        if (socket) {
          reloadCount = RELOAD_LIMITED;
          socket.close();
          socket = null;
        }
        break;
      case subscribeNotificationRequest.type:
        if (socket) {
          socket.send(
            JSON.stringify({
              action: 'subscribe',
              key: 'notificationId',
              value: payload?.notificationId,
            }),
          );
        }
        break;
      case subscribeGroupRequest.type:
        if (socket) {
          socket.send(
            JSON.stringify({
              action: 'subscribe',
              key: 'groupId',
              value: payload?.groupId,
            }),
          );
        }
        break;
      case unsubscribeGroupRequest.type:
        if (socket) {
          socket.send(
            JSON.stringify({
              action: 'unsubscribe',
              key: 'groupId',
            }),
          );
        }
        break;
      case pingToSocket.type:
        if (socket) {
          socket.send(JSON.stringify({ action: 'ping' }));
        }
        break;
      default:
        break;
    }

    // eslint-disable-next-line consistent-return
    return next(action);
  };
};

export default socketMiddleware();
