/**
 *
 * AuthorizationReportingProcess
 *
 */
import React, { memo } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const AuthorizationReportingProcess = () => (
  <Placeholder animation="glow">
    <div className="pvda-authorization-reporting-process-loading">
      <div style={{ display: 'grid' }}>
        <Placeholder
          style={{
            width: 150,
            height: 32,
          }}
        />
        <Placeholder
          style={{
            width: 400,
            height: 19,
            marginTop: 4,
          }}
        />
      </div>
      <div
        style={{
          display: 'grid',
          rowGap: 16,
        }}
      >
        <Placeholder
          style={{
            width: 71,
            height: 14,
          }}
        />
        {Array.from(Array(3), (_, i) => (
          <div
            key={i}
            style={{
              padding: '12px 24px 15px 24px',
              borderRadius: 4,
              boxShadow: '0px 0px 1px #747474',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder
              style={{
                width: 24,
                height: 24,
                borderRadius: '50%',
              }}
            />
            <div
              style={{
                marginLeft: 12,
                flex: 1,
                display: 'grid',
              }}
            >
              <Placeholder
                style={{
                  width: 120,
                  height: 22,
                }}
              />
              <Placeholder
                style={{
                  marginTop: 4,
                  width: 160,
                  height: 19,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </Placeholder>
);

AuthorizationReportingProcess.propTypes = {};

AuthorizationReportingProcess.defaultProps = {};

export default memo(AuthorizationReportingProcess);
