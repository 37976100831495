/**
 *
 * SkeletonLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RecentlyReportsLoading = ({ counter }) =>
  Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-recently-published-report-loading-item">
      <div>
        <div className="pvda-recently-published-report-loading-item__type" />
        <div className="pvda-recently-published-report-loading-item__name" />
        <div className="pvda-recently-published-report-loading-item__published-date" />
      </div>
      <div
        style={{
          height: '100%',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div className="pvda-recently-published-report-loading-item__report-duration-title" />
          <div className="pvda-recently-published-report-loading-item__report-duration-value" />
        </div>
        <div className="pvda-recently-published-report-loading-item__avatar" />
      </div>
    </div>
  ));

RecentlyReportsLoading.propTypes = { counter: PropTypes.number };

RecentlyReportsLoading.defaultProps = { counter: 0 };

export default memo(RecentlyReportsLoading);
