import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const MyRecentLoading = ({ counter }) =>
  Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-my-feed-loading">
      <Placeholder as="p" animation="glow">
        <Placeholder xs={4} size="xs" />
      </Placeholder>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={12} />
        <Placeholder xs={4} />
      </Placeholder>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={4} size="xs" />
      </Placeholder>
    </div>
  ));

MyRecentLoading.propTypes = { counter: PropTypes.number };

MyRecentLoading.defaultProps = { counter: 0 };

export default memo(MyRecentLoading);
