import { isNullOrUndefined } from '../format/number';

export const standardizedLabelsData = labels =>
  labels?.map(({ detailData, ...otherProps }) => {
    if (!detailData) {
      return otherProps;
    }

    const { kpiData } = detailData;

    const variance = kpiData?.variances?.find(
      ({ value }) => !isNullOrUndefined(value),
    );

    return {
      ...otherProps,
      detailData: {
        ...detailData,
        kpiData: {
          ...kpiData,
          variance,
        },
      },
    };
  });

export const standardizedKPIData = kpiData => {
  const {
    frequency,
    kpi,
    parentMstId,
    performanceAnalysesForTable,
    performanceAnalysesForChart,
    performanceAnalysesForWaterfallChart,
    ...payload
  } = kpiData;

  return {
    frequency,
    kpi,
    parentMstId,
    performanceAnalysesForTable,
    performanceAnalysesForChart: {
      ...performanceAnalysesForChart,
      labels: standardizedLabelsData(performanceAnalysesForChart?.labels),
    },
    performanceAnalysesForWaterfallChart,
    ...payload,
  };
};
