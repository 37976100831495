import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { BiLogOut } from 'react-icons/bi';

import emailIcon from '../../../assets/images/icons/envelope.svg';
import teamsIcon from '../../../assets/images/icons/teams.svg';
import petronasLogo from '../../../assets/images/mini-full-logo.png';

import useAuth from '../../../hooks/useAuth';

import CustomizedOverlay from '../../core/CustomizedOverlay';
import Avatar from '../../core/Avatar';

import './styles.scss';

const UserInfoAvatar = ({
  showLogout,
  className,
  placement,
  userInfo,
  avatarSize,
  avatarStyle,
  styles,
}) => {
  const [show, setShow] = useState(false);

  const target = useRef(null);

  const styleClasses = ['pvda-user-info-avatar', className];

  const { userInfo: currentUserInfo, onLogoutWithRedirect } = useAuth();

  const userEmailAddress = userInfo?.email;

  return (
    <div className={styleClasses.join(' ')} style={styles}>
      <Button
        style={{
          padding: 0,
          border: 'none',
        }}
        variant="custom-no-border"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <Avatar
          src={userInfo?.photoUrl}
          url={userInfo?.userImageUrl}
          name={userInfo?.fullName}
          size={avatarSize}
          style={avatarStyle}
        />
      </Button>
      <CustomizedOverlay
        show={show}
        className="pvda-user-info-avatar__popover"
        popoverRef={target}
        placement={placement}
        onHide={setShow}
      >
        <>
          <div className="pvda-user-info-avatar__popover-header">
            <div className="pvda-user-info-avatar__info">
              <h5 className="pvda-user-info-avatar__info-fullName">
                {userInfo?.fullName}
              </h5>
              <p className="pvda-user-info-avatar__info-position">
                {`${userInfo?.position || ''} ${
                  userInfo?.department && userInfo?.position ? 'of' : ''
                } ${userInfo?.department || ''}`}
              </p>
            </div>
            <div className="pvda-user-info-avatar__logoAvatar">
              <img
                src={petronasLogo}
                alt="Petronas Logo"
                width={32}
                style={{
                  marginTop: -16,
                  marginRight: 16,
                }}
              />
              <Avatar
                size="large"
                src={userInfo?.photoUrl}
                url={userInfo?.userImageUrl}
                name={userInfo?.fullName}
              />
            </div>
          </div>

          {currentUserInfo?.data?.userName === userInfo?.userName &&
            showLogout === true && (
              <div className="pvda-user-info-avatar__popover-body">
                <Button
                  className="pvda-user-info-avatar__button btn btn-outline-custom-primary"
                  onClick={onLogoutWithRedirect}
                >
                  <BiLogOut style={{ marginRight: 4 }} />
                  Logout
                </Button>
              </div>
            )}
          {currentUserInfo?.data?.userName !== userInfo?.userName && (
            <div className="pvda-user-info-avatar__popover-body">
              <a
                className="pvda-user-info-avatar__button btn btn-outline-custom-primary"
                href={`https://teams.microsoft.com/l/chat/0/0?users=${userEmailAddress}&topicName=Start%20New%20Conversation`}
              >
                <img src={teamsIcon} alt="Teams Logo" />
                <span>Send message</span>
              </a>
              <a
                className="pvda-user-info-avatar__button btn btn-outline-custom-primary"
                href={`mailto:${userEmailAddress}`}
              >
                <img src={emailIcon} alt="Email Logo" />
                <span>Email</span>
              </a>
            </div>
          )}
        </>
      </CustomizedOverlay>
    </div>
  );
};

UserInfoAvatar.propTypes = {
  showLogout: PropTypes.bool,
  className: PropTypes.string,
  placement: PropTypes.string,
  avatarSize: PropTypes.string,
  avatarStyle: PropTypes.object,
  userInfo: PropTypes.object,
  styles: PropTypes.object,
};

UserInfoAvatar.defaultProps = {
  showLogout: false,
  className: '',
  placement: 'left',
  avatarSize: 'default',
  avatarStyle: {},
  userInfo: {},
  styles: {},
};

export default memo(UserInfoAvatar);
