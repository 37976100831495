import { put, takeEvery, call, delay } from 'redux-saga/effects';

import {
  getData,
  postData,
  patchData,
  deleteData,
  putData,
} from '../services/api';

import {
  getReportRequest,
  getReportSuccess,
  getReportFailure,
  getReportsByPropRequest,
  getReportsByPropSuccess,
  getReportsByPropFailure,
  addNewReportRequest,
  addNewReportSuccess,
  addNewReportFailure,
  editReportRequest,
  editReportSuccess,
  editReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
  updateStatusReportRequest,
  updateStatusReportSuccess,
  updateStatusReportFailure,
  republishReportRequest,
  republishReportSuccess,
  republishReportFailure,
  addOrUpdateReviewersRequest,
  addOrUpdateReviewersSuccess,
  addOrUpdateReviewersFailure,
} from '../slices/report';

import {
  REPORT_PATH,
  REPUBLISH_REPORT,
  UPDATE_STATUS_REPORT_PATH,
  FUNCTION_PATH,
} from '../constants/paths';

// const formatReviewersData = (reviewers = []) =>
//   reviewers.reduce((accumulator, currentValue) => {
//     const updatedCurrentValue = {
//       ...currentValue,
//       label: currentValue?.fullName,
//       value: currentValue?.email,
//     };
//     if (accumulator[currentValue.functionId]) {
//       accumulator[currentValue.functionId].push(updatedCurrentValue);

//       return accumulator;
//     }

//     accumulator[currentValue.functionId] = [updatedCurrentValue];

//     return accumulator;
//   }, {});

export function* getReportsByProp(params) {
  const { prop, url, query } = params?.payload || {};
  try {
    const { data } = yield call(getData, {
      url,
      params: query,
    });
    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(
        getReportsByPropSuccess({
          prop,
          data: Data || [],
        }),
      );
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(
      getReportsByPropFailure({
        prop,
        message,
      }),
    );
  }
}

export function* getReport({ payload }) {
  const { query } = payload;

  try {
    const { data } = yield call(getData, {
      url: `${REPORT_PATH}`,
      params: query,
    });
    const { IsSuccess, StatusCode, Data: reportData } = data;

    if (IsSuccess && StatusCode === 200) {
      // data response correctly, ignore format reviewer data
      // reportData.stakeholders.reviewers = formatReviewersData(
      //   reportData.stakeholders.reviewers,
      // );
      yield delay(800);
      yield put(getReportSuccess(reportData));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getReportFailure(message));
  }
}

export function* addNewReport({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: REPORT_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data: newReport } = data;

    if (IsSuccess && StatusCode === 201) {
      yield delay(800);
      yield put(addNewReportSuccess());

      if (payload?.callback) {
        payload?.callback({
          isSuccess: true,
          newReport,
        });
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback({ isSuccess: false });
    }

    yield put(
      addNewReportFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* editReport({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: REPORT_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data: reportData } = data;

    if (IsSuccess && StatusCode === 200) {
      // reportData.stakeholders.reviewers = formatReviewersData(
      //   reportData.stakeholders.reviewers,
      // );
      yield delay(800);
      yield put(editReportSuccess(reportData));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      editReportFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* updateStatusReport({ payload }) {
  try {
    const { data } = yield call(patchData, {
      url: UPDATE_STATUS_REPORT_PATH,
      data: payload?.data,
    });

    const { IsSuccess, StatusCode, Data } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(
        updateStatusReportSuccess({
          data: Data,
          reportId: payload?.data?.reportId,
        }),
      );

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      updateStatusReportFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* deleteReportById({ payload }) {
  try {
    const { data } = yield call(deleteData, {
      url: `${REPORT_PATH}/${payload?.reportId}`,
    });

    const { IsSuccess, StatusCode } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(deleteReportSuccess({ reportId: payload?.reportId }));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      deleteReportFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* republishReport({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: `${REPUBLISH_REPORT}/${payload.data.reportId}`,
    });

    const { IsSuccess, StatusCode } = data;

    if (IsSuccess && StatusCode === 200) {
      yield put(republishReportSuccess(payload.data.reportId));

      if (payload?.callback) {
        payload?.callback(true);
      }
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    if (payload?.callback) {
      payload?.callback(false);
    }

    yield put(
      republishReportFailure({ error: responseMessage?.Message || message }),
    );
  }
}

export function* addOrUpdateReviewersInReport({ payload }) {
  const { callback, ...restPayload } = payload;
  try {
    const { data } = yield call(putData, {
      url: `${FUNCTION_PATH}/${payload.functionId}/UpdateReviewers`,
      data: restPayload,
    });

    const { IsSuccess, Data: reviewersData } = data;

    if (IsSuccess) {
      yield put(
        addOrUpdateReviewersSuccess({
          functionId: payload.functionId,
          reviewers: reviewersData,
        }),
      );

      if (callback) callback();
    }
  } catch (error) {
    const { message = 'Something went wrong!', response = {} } = error;
    const responseMessage = response?.data?.Data[0] || {};

    yield put(
      addOrUpdateReviewersFailure({
        error: responseMessage?.Message || message,
      }),
    );

    if (callback) callback();
  }
}

export default function* reportSaga() {
  yield takeEvery(getReportsByPropRequest().type, getReportsByProp);
  yield takeEvery(getReportRequest().type, getReport);
  yield takeEvery(addNewReportRequest().type, addNewReport);
  yield takeEvery(editReportRequest().type, editReport);
  yield takeEvery(updateStatusReportRequest().type, updateStatusReport);
  yield takeEvery(deleteReportRequest().type, deleteReportById);
  yield takeEvery(republishReportRequest().type, republishReport);
  yield takeEvery(
    addOrUpdateReviewersRequest().type,
    addOrUpdateReviewersInReport,
  );
}
