/**
 *
 * KPIDetailLoading
 *
 */
import React, { memo } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const KPIDetailLoading = () => (
  <div className="pvda-kpi-detail-loading">
    <Placeholder animation="glow">
      <div className="pvda-kpi-detail-loading__header">
        <Placeholder xs={3} style={{ height: 21 }} />
        <Placeholder
          xs={7}
          style={{
            height: 40,
            marginTop: 8,
            display: 'block',
          }}
        />
        <Placeholder
          xs={3}
          style={{
            height: 23,
            marginTop: 5,
          }}
        />
      </div>
      <div className="pvda-kpi-detail-loading__body">
        <div className="pvda-kpi-detail-loading__body-left">
          <Placeholder
            style={{
              width: '100%',
              height: 800,
            }}
          />
        </div>
        <div className="pvda-kpi-detail-loading__body-right">
          <Placeholder
            xs={3}
            style={{
              width: '100%',
              height: 329,
            }}
          />
        </div>
      </div>
    </Placeholder>
  </div>
);

KPIDetailLoading.propTypes = {};

KPIDetailLoading.defaultProps = {};

export default memo(KPIDetailLoading);
