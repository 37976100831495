export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const PINNED_CATEGORIES_LOADING = 'PINNED_CATEGORIES_LOADING';
export const RECENTLY_PUBLISHED_REPORTS_LOADING =
  'RECENTLY_PUBLISHED_REPORTS_LOADING';
export const PENDING_ACTIONS_LOADING = 'PENDING_ACTIONS_LOADING';
export const IN_PROGRESS_REPORTS_LOADING = 'IN_PROGRESS_REPORTS_LOADING';
export const MY_FEED_LOADING = 'MY_FEED_LOADING';
export const MY_RECENT_LOADING = 'MY_RECENT_LOADING';
export const REPORT_CREATION_HEADER_LOADING = 'REPORT_CREATION_HEADER_LOADING';
export const REPORT_INFO_LOADING = 'REPORT_INFO_LOADING';
export const AUTHORIZATION_REPORTING_PROCESS_LOADING =
  'AUTHORIZATION_REPORTING_PROCESS_LOADING';
export const INSIGHTS_LOADING = 'INSIGHTS_LOADING';
export const FUNCTIONS_KPIS_LOADING = 'FUNCTIONS_KPIS_LOADING';
export const KPI_DETAIL_LOADING = 'KPI_DETAIL_LOADING';
export const ACTIVITY_LOG_LOADING = 'ACTIVITY_LOG_LOADING';
export const VIEW_CHART_LOADING = 'VIEW_CHART_LOADING';
export const VIEW_CLARIFICATIONS_LOADING = 'VIEW_CLARIFICATIONS_LOADING';
export const EPIC_KEY_HIGHLIGHT_LOADING = 'EPIC_KEY_HIGHLIGHT_LOADING';
export const VIEW_PERFORMANCE_ANALYSIS_LOADING =
  'VIEW_PERFORMANCE_ANALYSIS_LOADING';
