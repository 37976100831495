/**
 *
 * ReportInfoLoading
 *
 */
import React, { memo } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ReportInfoLoading = () => (
  <div className="pvda-report-info-loading">
    <Placeholder animation="glow">
      <Placeholder
        style={{
          height: 93,
          display: 'block',
          borderRadius: '8px 8px 0px 0px',
        }}
      />
      {/* Contributors and Reviewers */}
      <div className="pvda-report-info-loading__container">
        {Array.from(Array(2), (parents, index) => (
          <div key={index}>
            <Placeholder
              style={{
                width: 76,
                height: 18,
              }}
            />
            <div
              style={{
                display: 'flex',
                marginTop: 8,
              }}
            >
              {Array.from(Array(3), (sub, subIndex) => (
                <Placeholder
                  key={subIndex}
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    marginRight: 4,
                  }}
                />
              ))}
            </div>
          </div>
        ))}
        {/* Approver */}
        <div>
          <Placeholder
            style={{
              width: 76,
              height: 18,
            }}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 4,
            }}
          >
            <Placeholder
              style={{
                width: 32,
                height: 32,
                borderRadius: '50%',
              }}
            />
            <div
              style={{
                marginLeft: 8,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Placeholder
                style={{
                  height: 20,
                  width: 100,
                }}
              />
              <Placeholder
                style={{
                  marginTop: 4,
                  height: 12,
                  width: 75,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="pvda-report-info-loading__container"
        style={{ borderTop: '1px solid #E7E7F0' }}
      >
        <div
          style={{
            width: '100%',
            display: 'grid',
            rowGap: 16,
          }}
        >
          <Placeholder
            style={{
              width: 67,
              height: 18,
            }}
          />
          <Placeholder
            style={{
              height: 120,
              width: 150,
            }}
          />
        </div>
      </div>
      <div
        className="pvda-report-info-loading__container"
        style={{ borderTop: '1px solid #E7E7F0', padding: '12px 24px' }}
      >
        <Placeholder
          style={{
            width: 67,
            height: 18,
          }}
        />
      </div>
    </Placeholder>
  </div>
);

ReportInfoLoading.propTypes = {};

ReportInfoLoading.defaultProps = {};

export default memo(ReportInfoLoading);
