/**
 *
 * ViewClarificationsLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const ViewClarificationsLoading = ({ counter }) => (
  <div className="pvda-view-clarifications-loading">
    {Array.from(Array(counter), (_, i) => (
      <Placeholder key={i} animation="glow">
        <Placeholder xs={12} style={{ height: 60 }} />
      </Placeholder>
    ))}
  </div>
);

ViewClarificationsLoading.propTypes = { counter: PropTypes.number };

ViewClarificationsLoading.defaultProps = { counter: 0 };

export default memo(ViewClarificationsLoading);
