/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import ProgressBar from 'react-bootstrap/ProgressBar';

import PrivateRoute from '../PrivateRoute';
import PublicRoute from '../PublicRoute';

const Routes = ({ routes, isAuthenticated }) => (
  <Suspense
    fallback={
      <ProgressBar className="pvda-b-progressbar pvda-b-progressbar--fixed" />
    }
  >
    <Switch>
      {routes.map(route => {
        if (route?.requiredAuthen) {
          return (
            <PrivateRoute
              key={route?.path}
              isAuthenticated={isAuthenticated}
              {...route}
            />
          );
        }

        return <PublicRoute key={route?.path} {...route} />;
      })}
    </Switch>
  </Suspense>
);

Routes.propTypes = {
  routes: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

Routes.defaultProps = {
  routes: [],
  isAuthenticated: false,
};

export default memo(Routes);
