/**
 *
 * InsightsLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';

import './styles.scss';

const InsightsLoading = ({ counter }) => (
  <div className="pvda-insight-loading">
    {Array.from(Array(counter), (_, i) => (
      <div key={i} className="pvda-insight-loading__card">
        <Placeholder animation="glow">
          <Placeholder
            xs={12}
            style={{
              borderRadius: '8px 8px 0px 0px',
              height: 134,
            }}
          />
          <div
            style={{
              padding: 24,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Type */}
            <Placeholder
              style={{
                width: 40,
                height: 12,
              }}
            />
            {/* Name */}
            <Placeholder
              style={{
                width: 100,
                height: 20,
                marginTop: 4,
              }}
            />
            {/* Description */}
            <Placeholder
              xs={12}
              style={{
                height: 40,
                marginTop: 8,
              }}
            />
            {/* Keywords */}
            <div
              style={{
                display: 'flex',
                marginTop: 16,
                gap: 8,
              }}
            >
              {Array.from(Array(2), (value, idx) => (
                <Placeholder
                  key={idx}
                  style={{
                    width: 40,
                    height: 18,
                  }}
                />
              ))}
            </div>
          </div>
        </Placeholder>
      </div>
    ))}
  </div>
);

InsightsLoading.propTypes = { counter: PropTypes.number };

InsightsLoading.defaultProps = { counter: 3 };

export default memo(InsightsLoading);
