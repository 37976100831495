import React, { memo } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const KeyHighlightLoading = () => (
  <Placeholder animation="glow">
    <div className="epic-key-highlight-loading">
      <Placeholder
        style={{
          height: 73,
        }}
      />
      <Placeholder
        style={{
          height: 301,
        }}
      />
      <Placeholder
        xs={12}
        style={{
          height: 158,
        }}
      />
    </div>
  </Placeholder>
);

KeyHighlightLoading.propTypes = {};

KeyHighlightLoading.defaultProps = {};

export default memo(KeyHighlightLoading);
