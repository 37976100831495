export const REPORTS = 'reports';
export const RECENTLY_UPDATED_REPORTS = 'recentlyUpdatedReports';
export const RECENTLY_PUBLISHED_REPORTS = 'recentlyPublishedReports';
export const PUBLISHED_REPORTS_TAB = 'publishedReportsTab';
export const MY_REPORTS_TAB = 'myReportsTab';
export const MY_ACTION_REPORTS_TAB = 'myActionReportsTab';
export const RECENTLY_VISITED_REPORTS = 'recentlyVisitedReports';
export const RECENTLY_FAVOURITE_REPORTS = 'recentlyFavouriteReports';
export const PENDING_REPORTS = 'pendingReports';
export const CATEGORIES = 'categories';
export const PINNED_CATEGORIES = 'pinnedCategories';
export const PINNED_CATEGORIES_ON_LANDING = 'pinnedCategoriesOnLanding';
export const CATEGORIES_ON_REPORT_LISTING = 'categoriesOnReportListing';
export const IN_PROGRESS_REPORTS = 'inProgressReports';
export const CONTRIBUTING_KPIS = 'contributingKPIs';
export const IMPACTED_KPIS = 'impactedKPIs';
