import { call, put, takeEvery } from 'redux-saga/effects';

import { EPIC } from 'constants/roles';
import { GET_OPU_PATH } from 'constants/paths';

import { getData } from 'services/api';

import { getOPUsRequest, getOPUsSuccess, getOPUsFailure } from 'slices/opu';

const mergeActions = roles => [
  ...new Set(
    roles
      .map(({ action }) => action.map(({ accessName }) => accessName))
      .flat(),
  ),
];

export const mergePermissions = roles => ({
  roles: roles.map(role => role.name),
  actions: mergeActions(roles),
});

export function* getOPUs() {
  try {
    const { data } = yield call(getData, { url: GET_OPU_PATH });

    const { IsSuccess, StatusCode, Data } = data;
    if (IsSuccess && StatusCode === 200) {
      /* Please dont delete the code below because may be BA will change requirements again */
      // const updatedData = Data.map(opu => {
      //   const updatedOPU = { ...opu, isParent: true };

      //   if (updatedOPU.roles && updatedOPU.roles.length > 0) {
      //     updatedOPU.permissions = mergePermissions(updatedOPU.roles);
      //     updatedOPU.children = opu.children?.map(children => ({
      //       ...children,
      //       permissions: mergePermissions(children.roles),
      //     }));

      //     updatedOPU.isEPIC = opu.type === EPIC;
      //   }

      //   return updatedOPU;
      // });

      const updatedData = Data.reduce((acc, opu) => {
        const updatedOPU = { ...opu };
        updatedOPU.isEPIC = opu.type === EPIC;

        if (updatedOPU?.roles && updatedOPU?.roles.length > 0) {
          updatedOPU.permissions = mergePermissions(updatedOPU?.roles);
          updatedOPU.children = [];
        }

        acc.push({ ...updatedOPU });

        if (opu?.children && opu?.children?.length > 0) {
          const opuChildren = opu?.children?.map(children => ({
            ...children,
            permissions: mergePermissions(children?.roles),
          }));

          acc.push(...opuChildren);
        }

        return acc;
      }, []);

      yield put(getOPUsSuccess(updatedData));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getOPUsFailure(message));
  }
}

export default function* opuSaga() {
  yield takeEvery(getOPUsRequest().type, getOPUs);
}
