const CATEGORY_REQUEST_ACCESS_ACTION_TYPE = {
  REQUEST: 3,
  APPROVE: 1,
  REJECT: 2,
};
const CATEGORY_NOTIFICATION_REQUEST_ACCESS_TYPE = {
  REJECT: 'CategoryRejected',
};

const CATEGORY_REQUEST_ACCESS_STATUS = { REQUEST_SUCCEED: 1 };
const CATEGORY_APPROVE_REJECT_REQUEST_ACCESS_STATUS = {
  SUCCEED: 1,
  NO_ACTION: 3,
  NOT_ALLOW: 4,
};

export {
  CATEGORY_REQUEST_ACCESS_ACTION_TYPE,
  CATEGORY_NOTIFICATION_REQUEST_ACCESS_TYPE,
  CATEGORY_REQUEST_ACCESS_STATUS,
  CATEGORY_APPROVE_REJECT_REQUEST_ACCESS_STATUS,
};
