import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'insight';
const initialState = {
  insights: {
    status: 'idle',
    data: [],
    error: null,
  },
  addInsight: {
    status: 'idle',
    data: {},
    error: null,
  },
  editInsight: {
    status: 'idle',
    data: {},
    error: null,
  },
  deleteInsight: {
    status: 'idle',
    data: {},
    error: null,
  },
};

const insightSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    getInsightsRequest(state) {
      state.insights.status = 'loading';
    },
    getInsightsSuccess(state, action) {
      state.insights.data = action.payload;
      state.insights.status = 'succeeded';
      state.insights.error = null;
    },
    getInsightsFailure(state, action) {
      state.insights.status = 'failed';
      state.insights.data = [];
      state.insights.error = action.payload;
    },
    addInsightRequest(state) {
      state.addInsight.status = 'loading';
    },
    addInsightSuccess(state, action) {
      state.insights.data = [{ ...action.payload }, ...state.insights.data];
      state.addInsight.status = 'succeeded';
      state.addInsight.error = null;
    },
    addInsightFailure(state, action) {
      state.addInsight.status = 'failed';
      state.addInsight.data = {};
      state.addInsight.error = action.payload.error;
    },
    // edit
    editInsightRequest(state) {
      state.editInsight.status = 'loading';
    },
    editInsightSuccess(state, action) {
      const idxInsightUpdated = state.insights.data.findIndex(
        insight => insight.id === action.payload.id,
      );

      state.insights.data[idxInsightUpdated] = action.payload;
      state.editInsight.status = 'succeeded';
      state.editInsight.error = null;
    },
    editInsightFailure(state, action) {
      state.editInsight.status = 'failed';
      state.editInsight.data = {};
      state.editInsight.error = action.payload.error;
    },
    deleteInsightRequest(state) {
      state.deleteInsight.status = 'loading';
    },
    deleteInsightSuccess(state, action) {
      state.insights.data = state.insights.data.filter(
        insight => insight.id !== action.payload.insightId,
      );
      state.deleteInsight.status = 'succeeded';
      state.deleteInsight.error = null;
    },
    deleteInsightFailure(state, action) {
      state.deleteInsight.status = 'failed';
      state.deleteInsight.data = {};
      state.deleteInsight.error = action.payload;
    },
  },
});

export const {
  getInsightsRequest,
  getInsightsSuccess,
  getInsightsFailure,
  addInsightRequest,
  addInsightSuccess,
  addInsightFailure,
  editInsightRequest,
  editInsightSuccess,
  editInsightFailure,
  deleteInsightRequest,
  deleteInsightSuccess,
  deleteInsightFailure,
} = insightSlice.actions;
export default insightSlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const insightsSelector = createSelector(
  rootSelector,
  state => state.insights,
);
export const addInsightSelector = createSelector(
  rootSelector,
  state => state.addInsight,
);
export const editInsightSelector = createSelector(
  rootSelector,
  state => state.editInsight,
);
export const deleteInsightSelector = createSelector(
  rootSelector,
  state => state.deleteInsight,
);
