import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { convertObjectToParams } from '../services/format/uri';

import {
  getReportsByPropRequest,
  reportsByPropSelector,
} from '../slices/newsReport';

const useNewsReport = prop => {
  const reports = useSelector(reportsByPropSelector(prop));

  const dispatch = useDispatch();

  const onGetReportsByProp = useCallback(params => {
    const { url, query } = params || {};

    dispatch(
      getReportsByPropRequest({
        ...params,
        prop,
        url: `${url}${convertObjectToParams(query)}`,
      }),
    );
  }, []);

  return {
    reports,
    onGetReportsByProp,
  };
};

export default useNewsReport;
