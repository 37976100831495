/**
 *
 * FunctionsKPIsLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './styles.scss';

const FunctionsKPIsLoading = ({ counter }) => (
  <div className="pvda-functions-kpi-loading">
    {Array.from(Array(counter), (_, i) => (
      <div key={i} className="pvda-functions-kpi-loading__card">
        <Placeholder
          animation="glow"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* KPI Category Name */}
          <Placeholder
            style={{
              width: 232,
              height: 24,
            }}
          />
          {/* Executive summary title */}
          <Placeholder
            style={{
              width: 124,
              height: 16,
              marginTop: 20,
            }}
          />
          {/* Executive summary */}
          <Placeholder
            xs={12}
            style={{
              height: 80,
              marginTop: 8,
            }}
          />
        </Placeholder>
      </div>
    ))}
  </div>
);

FunctionsKPIsLoading.propTypes = { counter: PropTypes.number };

FunctionsKPIsLoading.defaultProps = { counter: 3 };

export default memo(FunctionsKPIsLoading);
