import { combineReducers } from 'redux';

import errorReducer, { ROOT_STATE_NAME as ERROR } from '../slices/error';
import userReducer, { ROOT_STATE_NAME as USER } from '../slices/user';
import OPUReducer, { ROOT_STATE_NAME as OPU } from '../slices/opu';
import reportsReducer, { ROOT_STATE_NAME as REPORT } from '../slices/report';
import newsReportsReducer, {
  ROOT_STATE_NAME as NEWS_REPORT,
} from '../slices/newsReport';
import categoryReducer, {
  ROOT_STATE_NAME as CATEGORY,
} from '../slices/category';
import categoryTypeReducer, {
  ROOT_STATE_NAME as CATEGORY_TYPE,
} from '../slices/categoryType';
import insightReducer, {
  ROOT_STATE_NAME as INSIGHT_TYPE,
} from '../slices/insight';
import kpiReducer, { ROOT_STATE_NAME as KPI_TYPE } from '../slices/kpi';
import functionReducer, {
  ROOT_STATE_NAME as FUNCTION,
} from '../slices/function';
import clarificationReducer, {
  ROOT_STATE_NAME as CLARIFICATION,
} from '../slices/clarification';
import socketReducer, { ROOT_STATE_NAME as SOCKET } from '../slices/socket';

export default function createRootReducer() {
  const rootReducer = combineReducers({
    [ERROR]: errorReducer,
    [USER]: userReducer,
    [OPU]: OPUReducer,
    [REPORT]: reportsReducer,
    [NEWS_REPORT]: newsReportsReducer,
    [CATEGORY]: categoryReducer,
    [CATEGORY_TYPE]: categoryTypeReducer,
    [INSIGHT_TYPE]: insightReducer,
    [KPI_TYPE]: kpiReducer,
    [FUNCTION]: functionReducer,
    [CLARIFICATION]: clarificationReducer,
    [SOCKET]: socketReducer,
  });

  return rootReducer;
}
