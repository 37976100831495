import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { connectSocketRequest, pingToSocket } from 'slices/socket';

const useSocialService = isAuthenticated => {
  const dispatch = useDispatch();

  const onConnectSocialService = useCallback(params => {
    dispatch(connectSocketRequest(params));
  }, []);
  const onPingToSocket = useCallback(() => {
    dispatch(pingToSocket());
  }, []);

  useEffect(() => {
    let interval;

    if (isAuthenticated) {
      onConnectSocialService();

      interval = setInterval(() => {
        onPingToSocket();
      }, 45000);
    }

    return () => interval && clearInterval(interval);
  }, [isAuthenticated]);
};

export default useSocialService;
