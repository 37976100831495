import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  RECENTLY_PUBLISHED_REPORTS,
  REPORTS,
  PUBLISHED_REPORTS_TAB,
  MY_REPORTS_TAB,
  MY_ACTION_REPORTS_TAB,
} from '../constants/props';

export const ROOT_STATE_NAME = 'report';
const initialState = {
  report: {
    data: {},
    status: 'idle', // or: 'loading', 'succeeded', 'failed'
    error: null,
  },
  addNewReport: {
    status: 'idle',
    data: null,
    error: null,
  },
  editReport: {
    status: 'idle',
    data: null,
    error: null,
  },
  deletedReport: {
    status: 'idle',
    data: null,
    error: null,
  },
  [REPORTS]: {
    data: [],
    status: 'idle',
    error: null,
  },
  [RECENTLY_PUBLISHED_REPORTS]: {
    data: [],
    status: 'idle',
    error: null,
  },
  [PUBLISHED_REPORTS_TAB]: {
    data: [],
    status: 'idle',
    error: null,
  },
  [MY_REPORTS_TAB]: {
    data: [],
    status: 'idle',
    error: null,
  },
  [MY_ACTION_REPORTS_TAB]: {
    data: [],
    status: 'idle',
    error: null,
  },
  updateStatusReport: {
    status: 'idle',
    error: null,
  },
  republishReport: { status: 'idle', error: null },
  addedOrUpdatedReviewers: { status: 'idle', error: null },
};

const ReportsSilece = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    // report
    getReportRequest(state) {
      state.report.status = 'loading';
    },
    getReportSuccess(state, action) {
      state.report.data = action.payload;
      state.report.status = 'succeeded';
      state.report.error = null;
    },
    getReportFailure(state, action) {
      state.report.status = 'failed';
      state.report.data = [];
      state.report.error = action.payload;
    },
    // reports by prop
    getReportsByPropRequest(state, action) {
      state[action.payload.prop].status = 'loading';
    },
    getReportsByPropSuccess(state, action) {
      state[action.payload.prop].data = action.payload.data;
      state[action.payload.prop].status = 'succeeded';
      state[action.payload.prop].error = null;
    },
    getReportsByPropFailure(state, action) {
      state[action.payload.prop].status = 'failed';
      state[action.payload.prop].data = [];
      state[action.payload.prop].error = action.payload.message;
    },
    // create report
    addNewReportRequest(state) {
      state.addNewReport.status = 'loading';
    },
    addNewReportSuccess(state) {
      state.addNewReport.status = 'succeeded';
      state.addNewReport.error = null;
    },
    addNewReportFailure(state, action) {
      state.addNewReport.status = 'failed';
      state.addNewReport.error = action.payload.error;
    },
    // edit report
    editReportRequest(state) {
      state.editReport.status = 'loading';
    },
    editReportSuccess(state, action) {
      state.report.data = action.payload;
      state.editReport.status = 'succeeded';
      state.editReport.error = null;
    },
    editReportFailure(state, action) {
      state.editReport.status = 'failed';
      state.editReport.error = action.payload.error;
    },
    deleteReportRequest(state) {
      state.deletedReport.status = 'loading';
    },
    deleteReportSuccess(state) {
      state.deletedReport.status = 'succeeded';
      state.deletedReport.error = null;
    },
    deleteReportFailure(state, action) {
      state.deletedReport.status = 'failed';
      state.deletedReport.error = action.payload.error;
    },
    // update status report
    updateStatusReportRequest(state) {
      state.updateStatusReport.status = 'loading';
    },
    updateStatusReportSuccess(state, action) {
      state.updateStatusReport.status = 'succeeded';
      state.updateStatusReport.error = null;

      if (state.report.data.id === action.payload.reportId) {
        state.report.data = {
          ...state.report.data,
          status: action.payload.data.status,
          stakeholders: action.payload.data.stakeholders,
          updatedAt: new Date(),
        };
      }
    },
    updateStatusReportFailure(state, action) {
      state.updateStatusReport.status = 'failed';
      state.updateStatusReport.error = action.payload.error;
    },
    republishReportRequest(state) {
      state.republishReport.status = 'loading';
    },
    republishReportSuccess(state, action) {
      state.republishReport.status = 'succeeded';
      state.republishReport.error = null;

      if (state.report.data.id === action.payload) {
        state.report.data = {
          ...state.report.data,
          isRepublishReport: false,
        };
      }
    },
    republishReportFailure(state, action) {
      state.republishReport.status = 'failed';
      state.republishReport.error = action.payload.error;
    },
    // update updatedAt
    updateTimeOfReport(state, action) {
      state.report.data = {
        ...state.report.data,
        updatedAt: action.payload,
      };
    },
    updateSectionReport(state, action) {
      state.report.data = {
        ...state.report.data,
        ...action.payload,
      };
    },
    addOrUpdateReviewersRequest(state) {
      state.addedOrUpdatedReviewers.status = 'loading';
    },
    addOrUpdateReviewersSuccess(state, action) {
      state.addedOrUpdatedReviewers.status = 'succeeded';
      state.report.data.stakeholders.reviewers = [
        ...(state.report.data.stakeholders.reviewers.filter(
          reviewer =>
            reviewer.functionId &&
            reviewer.functionId !== action.payload.functionId,
        ) || []),
        ...action.payload.reviewers,
      ];
    },
    addOrUpdateReviewersFailure(state, action) {
      state.addedOrUpdatedReviewers.status = 'failed';
      state.republishReport.error = action.payload.error;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = ReportsSilece;
// Export the reducer, either as a default or named export
export default reducer;
// Extract and export each action creator by name
export const {
  getReportRequest,
  getReportSuccess,
  getReportFailure,
  getReportsByPropRequest,
  getReportsByPropSuccess,
  getReportsByPropFailure,
  addNewReportRequest,
  addNewReportSuccess,
  addNewReportFailure,
  editReportRequest,
  editReportSuccess,
  editReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
  updateStatusReportRequest,
  updateStatusReportSuccess,
  updateStatusReportFailure,
  republishReportRequest,
  republishReportSuccess,
  republishReportFailure,
  updateTimeOfReport,
  updateSectionReport,
  addOrUpdateReviewersRequest,
  addOrUpdateReviewersSuccess,
  addOrUpdateReviewersFailure,
} = actions;
// Create and export each selector create by name
export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const reportSelector = () =>
  createSelector(rootSelector, state => state.report);
export const reportsByPropSelector = prop =>
  createSelector(rootSelector, state => state[prop]);
export const addNewReportSelector = createSelector(
  rootSelector,
  state => state.addNewReport,
);
export const editReportSelector = createSelector(
  rootSelector,
  state => state.editReport,
);
export const deletedReportSelector = createSelector(
  rootSelector,
  state => state.deletedReport,
);
export const updateStatusReportSelector = createSelector(
  rootSelector,
  state => state.updateStatusReport,
);
export const republishReportSelector = createSelector(
  rootSelector,
  state => state.republishReport,
);
export const addedOrUpdatedReviewersSelector = createSelector(
  rootSelector,
  state => state.addedOrUpdatedReviewers,
);
