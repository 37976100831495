import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const ROOT_STATE_NAME = 'socket';
const initialState = {
  socket: {
    status: 'idle',
    data: {},
  },
  subscribeNotification: {
    status: 'idle',
    data: {},
  },
  subscribeGroup: {
    status: 'idle',
    data: {},
  },
};

const clarificationSlice = createSlice({
  name: ROOT_STATE_NAME,
  initialState,
  reducers: {
    connectSocketRequest(state) {
      state.socket.status = 'loading';
    },
    connectSocketSuccess(state, action) {
      state.socket.data = action.payload;
      state.socket.status = 'succeeded';
    },
    connectSocketFail(state) {
      state.socket.status = 'failed';
    },
    disconnectSocket(state) {
      state.socket = 'idle';
    },
    subscribeNotificationRequest(state) {
      state.subscribeNotification.status = 'loading';
    },
    subscribeGroupRequest(state) {
      state.subscribeGroup.status = 'loading';
    },
    unsubscribeGroupRequest(state) {
      state.subscribeGroup.status = 'idle';
    },
    pingToSocket() {},
  },
});

export const {
  connectSocketRequest,
  connectSocketSuccess,
  connectSocketFail,
  disconnectSocket,
  subscribeNotificationRequest,
  subscribeGroupRequest,
  unsubscribeGroupRequest,
  pingToSocket,
} = clarificationSlice.actions;
export default clarificationSlice.reducer;

export const rootSelector = state => state[ROOT_STATE_NAME] || {};
export const socketSelector = createSelector(
  rootSelector,
  state => state.socket,
);
export const subscribeNotificationSelector = createSelector(
  rootSelector,
  state => state.subscribeNotification,
);
export const subscribeGroupSelector = createSelector(
  rootSelector,
  state => state.subscribeGroup,
);
