export const PENDING_TYPE = 1;
export const APPROVED_TYPE = 2;
export const REJECTED_TYPE = 3;

export const REPORT_STATUS_TYPES = {
  [PENDING_TYPE]: {
    id: PENDING_TYPE,
    text: 'PENDING',
    color: '#E56E18',
    bgColor: '#FEEABD',
  },
  [APPROVED_TYPE]: {
    id: APPROVED_TYPE,
    text: 'APPROVED',
    color: '#308196',
    bgColor: '#D9F2F7',
  },
  [REJECTED_TYPE]: {
    id: REJECTED_TYPE,
    text: 'REJECTED',
    color: '#E82121',
    bgColor: '#FFF2F2',
  },
};
