/**
 *
 * ReportsByStatusLoading
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Placeholder from 'react-bootstrap/Placeholder';

import './style.scss';

const ReportsByStatusLoading = ({ isPending, counter }) => {
  if (isPending) {
    return Array.from(Array(counter), (_, i) => (
      <div key={i} className="pvda-report-item-by-status-loading">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={4} size="xs" />
          <Placeholder xs={12} />
          <Placeholder xs={7} size="xs" />
          <Placeholder xs={7} size="xs" />
        </Placeholder>
      </div>
    ));
  }

  return Array.from(Array(counter), (_, i) => (
    <div key={i} className="pvda-report-item-by-status-loading">
      <Placeholder as="p" animation="glow">
        <Placeholder xs={4} size="xs" />
        <Placeholder xs={12} />
        <Placeholder xs={12} bg="custom-primary" />
        <Placeholder xs={8} size="xs" />
        <Placeholder xs={6} size="xs" />
      </Placeholder>
    </div>
  ));
};

ReportsByStatusLoading.propTypes = {
  isPending: PropTypes.bool,
  counter: PropTypes.number,
};

ReportsByStatusLoading.defaultProps = {
  isPending: false,
  counter: 0,
};

export default memo(ReportsByStatusLoading);
