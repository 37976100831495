import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import settings from '../settings';

import { ACCESS_TOKEN, CURRENT_PATH } from '../constants/storageKeys';

import { setStorage, getStorage, removeStorage } from '../services/storage';
import { convertParamsToObject } from '../services/format/uri';

import {
  isAuthenticatedSelector,
  userInfoSelector,
  updateIsAuthenticated,
  getUserInfoRequest,
} from '../slices/user';

const useAuth = () => {
  const history = useHistory();
  const location = useLocation();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userInfo = useSelector(userInfoSelector);

  const dispatch = useDispatch();

  const onUpdateIsAuthenticated = useCallback(() => {
    dispatch(updateIsAuthenticated(true));
  }, []);

  const onGetUserInfo = useCallback(params => {
    dispatch(getUserInfoRequest(params));
  }, []);

  const token = useMemo(() => getStorage(ACCESS_TOKEN), [isAuthenticated]);

  const onLoginWithRedirect = useCallback(
    (isTokenExpired = false) => {
      let pathname = '';
      if (isTokenExpired) {
        pathname = `${window?.location?.pathname}${window?.location?.search}`;
      } else if (location?.state?.from?.pathname) {
        pathname = location.state.from.pathname;
      } else {
        pathname = '/';
      }

      if (token && !isTokenExpired) {
        onUpdateIsAuthenticated();
        history.push(pathname);
      } else {
        setStorage({
          key: CURRENT_PATH,
          val: pathname,
        });
        window.location.replace(settings.REDIRECT_TO_AUTHENTICATION);
      }
    },
    [token, location?.state?.from?.pathname],
  );

  const onLogoutWithRedirect = useCallback(() => {
    removeStorage(CURRENT_PATH);
    removeStorage(ACCESS_TOKEN);
    window.location.replace(settings.REDIRECT_TO_SIGNOUT);
  }, []);

  const onReceiveAuthCallback = useCallback((hashParams = '') => {
    const { id_token: idToken } = convertParamsToObject(hashParams);

    if (idToken) {
      onUpdateIsAuthenticated();
      setStorage({
        key: ACCESS_TOKEN,
        val: idToken,
      });
      history.push(getStorage(CURRENT_PATH) || '/');
    }
  }, []);

  return {
    token,
    isAuthenticated,
    userInfo,
    onLoginWithRedirect,
    onLogoutWithRedirect,
    onReceiveAuthCallback,
    onGetUserInfo,
  };
};

export default useAuth;
