/* eslint-disable camelcase */
export const ROLE_READER = 'reader';

// Type of OPU
export const EPIC = 'EPIC';

// Type of user in report
export const PREPARED_BY = 'preparedBy';
export const CONTRIBUTORS = 'contributors';
export const REVIEWERS = 'reviewers';
export const APPROVER = 'approver';

// UAM roles
export const UAM_PREPARED_BY = 'reportMmrReporter';
export const UAM_CONTRIBUTORS = 'reportMmrContributor';
export const UAM_REVIEWERS = 'reportMmrReviewer';
export const UAM_APPROVER = 'reportMmrApprover';
export const UAM_SUPER_ADMIN = 'superAdmin';
export const UAM_OPU_SUPER_ADMIN = 'opuSuperAdmin';
export const UAM_OPU_REPORT_ADMIN = 'opuReportAdmin';
export const UAM_SUBSCRIBER_VIEW_ONLY = 'subscriberViewOnly';
export const UAM_KPI_OWNER = 'kpiOwner';

// UAM actions
export const MMR_CreateUpdateDeleteReportCategory =
  'uamCreateUpdateDeleteReportCategory';
export const MMR_PinnedAndUnpinnedReportCategory =
  'uamPinnedAndUnpinnedReportCategory';
export const MMR_CreateUpdateDeleteReportCategoryType =
  'uamCreateUpdateDeleteReportCategoryType';
export const MMR_ViewReportCategory = 'uamViewReportCategory';
export const MMR_ViewReportInMyReportsTab = 'uamViewReportInMyReportsTab';
export const MMR_EditReportInMyReportsTab = 'uamEditReportInMyReportsTab';
export const MMR_DeleteReportInMyReportsTab = 'uamDeleteReportInMyReportsTab';
export const MMR_ViewReportInPublishedReportsTab =
  'uamViewReportInPublishedReportsTab';
export const MMR_AmendDuplicateReport = 'uamAmendDuplicateReport';
export const MMR_DownloadReport = 'uamDownloadReport';
export const MMR_CreateNewReport = 'uamCreateNewReport';
export const MMR_EditReportTitleReportPeriodChangeContributorAndChangeReportPreparer =
  'uamEditReportTitleReportPeriodChangeContributorAndChangeReportPreparer';
export const MMR_AddKpiInTheScorecard = 'uamAddKpiInTheScorecard';
export const MMR_RemoveKpiInTheScorecard = 'uamRemoveKpiInTheScorecard';
export const MMR_PerformAnalysisForAllLevelOfData =
  'uamPerformAnalysisForAllLevelOfData';
export const MMR_RequestClarification = 'uamRequestClarification';
export const MMR_ReplyClarificationRequest = 'uamReplyClarificationRequest';
export const MMR_ViewClarificationMessages = 'uamViewClarificationMessages';
export const MMR_ViewReportInDraftStatus = 'uamViewReportInDraftStatus';
export const MMR_SendReportToReviewerReviewers =
  'uamSendReportToReviewerReviewers';
export const MMR_ViewReportInPendingReview = 'uamViewReportInPendingReview';
export const MMR_ApproveRejectTheReportInPendingReviewStatus =
  'uamApproveRejectTheReportInPendingReviewStatus';
export const MMR_SendReportToApprover = 'uamSendReportToApprover';
export const MMR_ViewReportInPendingApprove = 'uamViewReportInPendingApprove';
export const MMR_ApproveRejectTheReportInPendingApprovalStatus =
  'uamApproveRejectTheReportInPendingApprovalStatus';
export const MMR_ViewPublishedReport = 'uamViewPublishedReport';
export const MMR_ViewAllInProgressReportAndReportCreatedByAllUsers =
  'uamViewAllInProgressReportAndReportCreatedByAllUsers';
export const MMR_ViewAllInActionsReportAndReportCreatedByAllUsers =
  'uamViewAllInActionsReportAndReportCreatedByAllUsers';
