import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  CATEGORIES_LOADING,
  PINNED_CATEGORIES_LOADING,
  RECENTLY_PUBLISHED_REPORTS_LOADING,
  PENDING_ACTIONS_LOADING,
  IN_PROGRESS_REPORTS_LOADING,
  MY_FEED_LOADING,
  MY_RECENT_LOADING,
  REPORT_CREATION_HEADER_LOADING,
  REPORT_INFO_LOADING,
  INSIGHTS_LOADING,
  FUNCTIONS_KPIS_LOADING,
  AUTHORIZATION_REPORTING_PROCESS_LOADING,
  KPI_DETAIL_LOADING,
  ACTIVITY_LOG_LOADING,
  VIEW_CHART_LOADING,
  VIEW_CLARIFICATIONS_LOADING,
  EPIC_KEY_HIGHLIGHT_LOADING,
  VIEW_PERFORMANCE_ANALYSIS_LOADING,
} from 'constants/loadingTypes';

import CategoriesLoading from '../CategoriesLoading';
import PinnedCategoriesLoading from '../PinnedCategoriesLoading';
import RecentlyReportsLoading from '../RecentlyReportsLoading';
import ReportsByStatusLoading from '../ReportsByStatusLoading';
import MyFeedLoading from '../MyFeedLoading';
import MyRecentLoading from '../MyRecentLoading';
import ReportCreationHeaderLoading from '../ReportCreationHeaderLoading';
import ReportInfoLoading from '../ReportInfoLoading';
import AuthorizationReportingProcess from '../AuthorizationReportingProcess';
import InsightsLoading from '../InsightsLoading';
import FunctionsKPIsLoading from '../FunctionsKPIsLoading';
import KPIDetailLoading from '../KPIDetailLoading';
import ActivityLogLoading from '../ActivityLogLoading';
import ViewChartLoading from '../ViewChartLoading';
import ViewClarificationsLoading from '../ViewClarificationsLoading';
import KeyHighlightLoading from '../KeyHighlightLoading';
import ViewPerformanceAnalysisLoading from '../ViewPerformanceAnalysisLoading';

const SkeletonLoading = ({
  isTopLoading,
  isLoading,
  isLoadingMore,
  children,
  counter,
  type,
}) => {
  let loading;
  if (isLoading) {
    switch (type) {
      case CATEGORIES_LOADING:
        loading = <CategoriesLoading counter={counter} />;
        break;
      case PINNED_CATEGORIES_LOADING:
        loading = <PinnedCategoriesLoading counter={counter} />;
        break;
      case RECENTLY_PUBLISHED_REPORTS_LOADING:
        loading = <RecentlyReportsLoading counter={counter} />;
        break;
      case PENDING_ACTIONS_LOADING:
        loading = <ReportsByStatusLoading isPending counter={counter} />;
        break;
      case IN_PROGRESS_REPORTS_LOADING:
        loading = (
          <ReportsByStatusLoading isPending={false} counter={counter} />
        );
        break;
      case MY_FEED_LOADING:
        loading = <MyFeedLoading counter={counter} />;
        break;
      case MY_RECENT_LOADING:
        loading = <MyRecentLoading counter={counter} />;
        break;
      case REPORT_CREATION_HEADER_LOADING:
        loading = <ReportCreationHeaderLoading />;
        break;
      case REPORT_INFO_LOADING:
        loading = <ReportInfoLoading />;
        break;
      case AUTHORIZATION_REPORTING_PROCESS_LOADING:
        loading = <AuthorizationReportingProcess />;
        break;
      case INSIGHTS_LOADING:
        loading = <InsightsLoading />;
        break;
      case FUNCTIONS_KPIS_LOADING:
        loading = <FunctionsKPIsLoading />;
        break;
      case KPI_DETAIL_LOADING:
        loading = <KPIDetailLoading />;
        break;
      case ACTIVITY_LOG_LOADING:
        loading = <ActivityLogLoading counter={counter} />;
        break;
      case VIEW_CHART_LOADING:
        loading = <ViewChartLoading counter={counter} />;
        break;
      case VIEW_CLARIFICATIONS_LOADING:
        loading = <ViewClarificationsLoading counter={counter} />;
        break;
      case EPIC_KEY_HIGHLIGHT_LOADING:
        loading = <KeyHighlightLoading />;
        break;
      case VIEW_PERFORMANCE_ANALYSIS_LOADING:
        loading = <ViewPerformanceAnalysisLoading />;
        break;
      default:
        loading = <div>LOADING...</div>;
        break;
    }
  }

  if (isLoading) {
    if (isTopLoading) {
      return (
        <>
          {loading}
          {isLoadingMore && children}
        </>
      );
    }

    return (
      <>
        {isLoadingMore && children}
        {loading}
      </>
    );
  }

  return children;
};

SkeletonLoading.propTypes = {
  isTopLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  counter: PropTypes.number,
  type: PropTypes.string,
  children: PropTypes.any,
};

SkeletonLoading.defaultProps = {
  isTopLoading: false,
  isLoading: false,
  isLoadingMore: true,
  counter: 6,
  type: '',
  children: null,
};

export default memo(SkeletonLoading);
