import { lazy } from 'react';

const routes = [
  {
    path: '/',
    component: lazy(() => import('pages/LandingPage')),
    exact: true,
    requiredAuthen: true,
  },
  {
    path: '/auth',
    component: lazy(() => import('pages/AuthPage')),
    exact: true,
  },
  {
    path: '/auth/callback',
    component: lazy(() => import('pages/CallbackAuthPage')),
    exact: true,
  },
  {
    path: '/report-listing',
    component: lazy(() => import('pages/ReportListingPage')),
    exact: true,
    requiredAuthen: true,
  },
  {
    path: '/report-listing/:categoryId',
    component: lazy(() => import('pages/ReportListingPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/report-creation/:reportId',
    component: lazy(() => import('pages/ReportCreationPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/report-view/:reportId',
    component: lazy(() => import('./pages/ReportViewPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/performance-analysis/:reportId/:kpiId',
    component: lazy(() => import('pages/KPIDetailPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/epic-report-creation/:reportId',
    component: lazy(() => import('pages/ReportCreationEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/epic-report-view/:reportId',
    component: lazy(() => import('pages/ReportViewEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/epic-category-request-access',
    component: lazy(() => import('pages/CategoryRequestAccessEpicPage')),
    exact: false,
    requiredAuthen: true,
  },
  {
    path: '/error-page',
    component: lazy(() => import('pages/ErrorPage')),
    requiredAuthen: true,
    exact: false,
  },
  {
    path: '*',
    component: lazy(() => import('pages/NotFoundPage')),
    requiredAuthen: true,
    exact: false,
  },
];

export default routes;
