import { call, put, delay, takeEvery, takeLatest } from 'redux-saga/effects';

import { getData, postData } from '../services/api';

import {
  AUTHENTICATION_API_PATH,
  GET_COLLABORATORS_UAM_PATH,
  GET_UAM_PATH,
} from '../constants/paths';

import {
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  getUsersUAMRequest,
  getUsersUAMSuccess,
  getUsersUAMFailure,
  getCollaboratorsUAMRequest,
  getCollaboratorsUAMSuccess,
  getCollaboratorsUAMFailure,
} from '../slices/user';

export function* getUserInfo() {
  try {
    const {
      data: { Data: userData },
    } = yield call(getData, { url: AUTHENTICATION_API_PATH });

    yield delay(800);
    yield put(getUserInfoSuccess(userData));
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getUserInfoFailure(message));
  }
}

export function* getUsersUAM({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: GET_UAM_PATH,
      data: payload,
    });

    const { IsSuccess, StatusCode, Data: userData } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(getUsersUAMSuccess(userData));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getUsersUAMFailure(message));
  }
}

export function* getCollaboratorsUAM({ payload }) {
  try {
    const { data } = yield call(postData, {
      url: GET_COLLABORATORS_UAM_PATH,
      data: payload,
    });

    const { IsSuccess, StatusCode, Data: userData } = data;

    if (IsSuccess && StatusCode === 200) {
      yield delay(800);
      yield put(getCollaboratorsUAMSuccess(userData));
    }
  } catch (error) {
    const { message = 'Something went wrong!' } = error;

    yield put(getCollaboratorsUAMFailure(message));
  }
}

export default function* userSaga() {
  yield takeEvery(getUserInfoRequest().type, getUserInfo);
  yield takeLatest(getUsersUAMRequest().type, getUsersUAM);
  yield takeLatest(getCollaboratorsUAMRequest().type, getCollaboratorsUAM);
}
